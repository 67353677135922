import guide from '@/api/guide';
import channels from '@/api/channels';
import programs from '@/api/programs';
import search from '@/api/search';

export const API = {
  guide,
  channels,
  programs,
  search,
};
