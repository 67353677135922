export const button = (wrapper) => {
	const VISIBLE_ARTICLES = 6;
	const allArticles = [...wrapper.querySelectorAll('.list-article')];
	const button = wrapper.parentNode.querySelector('.vertical-strip__button');
	let page = 2;

	wrapper.replaceChildren(...allArticles.slice(0, VISIBLE_ARTICLES));

	const loadArticles = () => {
		const articlesLength = wrapper.querySelectorAll('.list-article').length;

		if (allArticles.length > articlesLength) {
			const visibleResults = articlesLength + VISIBLE_ARTICLES;
			const articles = allArticles.slice(0, visibleResults);

			wrapper.replaceChildren(...articles);
		}

		if (allArticles.length - articlesLength < VISIBLE_ARTICLES) {
			button.classList.add('list-article--hide');
		}

		pushEvent(page);
		page +=1;
	};


	/* GTM | Web & Apps */
	const pushEvent = (page) => {
		window.didomiOnReady = window.didomiOnReady || [];
		window.didomiOnReady.push(function () {
			window.dataLayer.push({"event": "page_next", "page": {"pagination": {"type": "more", "page": page}}});
		});
	};

	button.addEventListener('click', loadArticles);
};

