<template>
  <div>
    <div class="guide" id="tv-program">
      <div class="guide__header">
        <div class="guide__title">
          <h2>TV program</h2>
        </div>
        <GuideSearch />
      </div>
      <Grid @headerPosition="setHeaderPosition" />
    </div>
    <GridLoadMore />
  </div>
</template>

<script>
import GuideSearch from '@/components/Guide/GuideSearch';
import Grid from '@/components/Guide/Grid/Grid';
import GridLoadMore from '@/components/Guide/Grid/GridLoadMore';

import {
  SET_CHANNELS_CATEGORY,
  SET_CHANNELS_FILTER,
  SET_CHANNELS,
  SET_CHANNELS_FILTER_EMPTY,
  SET_CHANNELS_LIST,
  SET_PROGRAMS,
  SET_RENDERING,
} from '@/store/actions/guide';
import { storage } from '@/storage';
import { flattenArray, getDate, splitToChunks, getUrlParams } from '@/utils';
import { mapGetters } from 'vuex';
import { API } from '@/api';
import {
  REQUEST_CHUNK_LIMIT,
  USER_DATE_CHANGE_TIMEOUT,
  FITLER_DEFAULT,
  PARAM_STATION,
} from '@/config';

let changePositionTimeout;

export default {
  name: 'Guide',

  components: {
    GuideSearch,
    Grid,
    GridLoadMore,
  },

  data() {
    return {
      headerPosition: '',
    };
  },

  computed: {
    ...mapGetters([
      'channels',
      'programs',
      'userPosition',
      'channelsList',
      'channelsFilter',
      'favoriteChannels',
    ]),
  },

  watch: {
    'userPosition.dates'() {
      clearTimeout(changePositionTimeout);
      this.$store.dispatch(SET_RENDERING, true);

      changePositionTimeout = setTimeout(async () => {
        await this.getPrograms();
        this.$store.dispatch(SET_RENDERING, false);
      }, USER_DATE_CHANGE_TIMEOUT);
    },

    async channels() {
      this.$store.dispatch(SET_RENDERING, true);
      await this.getPrograms();
      this.$store.dispatch(SET_RENDERING, false);
    },
  },

  async mounted() {
    const response = await API.guide.getChannels();
    const channelsCategory = await API.guide.getChannelsCategory();
    this.$store.dispatch(SET_CHANNELS_LIST, response);
    this.$store.dispatch(SET_CHANNELS_FILTER, storage.get()['channelsFilter'] ?? FITLER_DEFAULT);
    this.$store.dispatch(SET_CHANNELS_CATEGORY, channelsCategory);

    // Only if there is a station ID in the url... example: '?stanice=32'
    const stanice = getUrlParams(window.location.search)[PARAM_STATION];
    if (stanice) {
      const channel = this.channelsList.find((channel) => channel.id === stanice);
      this.$store.dispatch(SET_CHANNELS_FILTER_EMPTY);
      this.$store.dispatch(SET_CHANNELS, [channel]);
      window.history.replaceState({}, document.title, '/');
    }
  },

  methods: {
    async getPrograms() {
      const ids = this.channels.map((channel) => channel.id);

      if (!this.userPosition.dates) return false;

      const response = await Promise.all(
        this.userPosition.dates.map((date) => {
          if (!this.programs[date]) {
            return this.getProgram(date, ids);
          } else {
            const requireChannels = ids.filter((id) => !this.programs[date][id]);
            if (requireChannels.length === 0) return [];
            return this.getProgram(date, requireChannels);
          }
        })
      );

      const data = flattenArray(response);
      if (data.length !== 0) this.$store.dispatch(SET_PROGRAMS, data);
    },

    async getProgram(date, channels) {
      const chunks = splitToChunks(channels, REQUEST_CHUNK_LIMIT);
      return Promise.all(
        chunks.map(async (chunk) => {
          const response = await API.guide.getPrograms({ channels: chunk, dates: [date] });
          const excludeStations = response?.meta?.excludeStations;
          if (excludeStations && excludeStations.length > 0) {
            response.data = [
              ...response.data,
              this.createUnavailablePrograms(excludeStations, date),
            ];
          }
          return response.data;
        })
      );
    },

    createUnavailablePrograms(excludeStations, date) {
      return excludeStations.map((station) => {
        const {
          startUnavailableProgramDateTime,
          endUnavailableProgramDateTime,
          stationId,
        } = station;
        const startDateTime =
          startUnavailableProgramDateTime || new Date(`${date.replaceAll('-', '/')} 00:00`);
        const endTime = endUnavailableProgramDateTime;
        let length;

        if (!endTime) {
          length = 1440;
        } else {
          length = getDate.toMinutes(endTime) - getDate.toMinutes(startDateTime);
        }

        return {
          type: 'program',
          id: 'unavailable',
          attributes: {
            name: 'Program není dostupný',
            station: stationId,
            lengthMinutes: length,
            date: date,
            startDateTime: startDateTime,
          },
        };
      });
    },

    setHeaderPosition(headerPosition) {
      if (headerPosition === 'fixed') this.headerPosition = headerPosition;
      if (headerPosition === 'sticky') this.headerPosition = headerPosition;
      if (headerPosition === 'none') this.headerPosition = headerPosition;
    }

  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.guide {
  position: relative;
  user-select: none;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: rem(29) rem(30) 0;
    background-color: $color-background-primary;
    border-top: rem(2) solid $color-border-primary;

    @include until($breakpoint-header) {
      flex-direction: column;
    }

    @include until($breakpoint-small) {
      padding: rem(29) rem(15) 0;

      &--fixed {
        position: fixed;
        z-index: 10;
        width: 100%;
        border-top: 0;
        top: -110px;
      }

      &--sticked {
        position: absolute;
        bottom: 163px;
        left: 0;
        z-index: 10;
        width: 100%;
      }
    }

    @include from($breakpoint-small) {
      height: rem(188);
    }

    @include from($breakpoint-header) {
      height: rem(170);
    }

    @include from($breakpoint-header-large) {
      height: rem(135);
    }

    @include from($breakpoint-header-big) {
      height: rem(90);
    }
  }

  &__title {
    width: rem(197);

    @include until($breakpoint-small) {
      padding-bottom: rem(54);
    }
  }

  /deep/ &__channels-filter {
    @include until($breakpoint-small) {
      flex: none;

      .channels-filter__inner {
        flex-wrap: nowrap;
      }
    }
  }

  /deep/ &__search {
    width: rem(360);
    padding-left: rem(30);

    @include until($breakpoint-header) {
      position: absolute;
      top: rem(20);
      right: rem(30);
    }

    @include until($breakpoint-small) {
      right: rem(15);
      width: 100%;
      top: rem(65);
    }
  }

  /deep/ .whisperer {
    right: 0;
    left: rem(30);
  }
}
</style>
