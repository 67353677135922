<template>
  <div class="episodes" :class="{'episodes--closed': !isOpen}">
    <h2 class="episodes__title" @click="toggleEpisodes">
      Další díly a reprízy
      <span class="episodes__accordion-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9">
          <path d="M1.77 8.486L7.725 2.61l5.955 5.875L15 7.18 7.726-.001.45 7.18z" fill="#ffffff"/>
        </svg>
      </span>
    </h2>
    <transition name="episodes__accordion-effect">
      <ul class="episodes__list" v-show="isOpen">
        <li class="episodes__item" v-for="episode in programDataEpisodes.slice(0, 5)" :key="episode.id">
          <div class="episodes__item-left">
            <time class="episodes__episode-date">
              {{ startDate(episode.startDateTime) }}
            </time>
            <time class="episodes__episode-time">
              {{ startTime(episode.startDateTime) }}
            </time>
          </div>
          <div class="episodes__item-right">
            <span class="episodes__episode-series" v-if="episode.seasonName">
              {{ episode.seasonName }}
            </span>
            <span class="episodes__episode-part" v-if="episode.seasonNumber > 0">
              {{ episode.seasonNumber }}. díl
            </span>
            <a :data-id="episode.id"
               :href="`/#detail=${episode.id}`"
               class="episodes__episode-title"
               @click="toggleDetailHandler"
            >
              {{ episode.name }} <span v-if="episode.seasonNumber > 0"
                                       class="episodes__episode-part episodes__episode-part--mobile">{{
                episode.seasonNumber
              }}. díl</span>
            </a>
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import {getDate} from "@/utils";
import {TOGGLE_PROGRAM_DETAIL} from '@/store/actions/program-detail';

export default {
  name: 'ProgramDetailEpisodes',

  props: {
    programDataEpisodes: Array,
  },

  data() {
    return {
      isOpen: true,
    }
  },

  methods: {
    toggleEpisodes() {
      this.isOpen = !this.isOpen;
    },

    startTime(data) {
      let startDateTime = getDate.parsedDate(data);
      let startMinutes = startDateTime.minutes < 10 ? '0' + startDateTime.minutes : startDateTime.minutes;

      return `${startDateTime.hours}:${startDateTime.minutes === 0 ? '00' : startMinutes}`;

    },

    startDate(data) {
      let startDateTime = getDate.parsedDate(data);

      return `${startDateTime.day}. ${startDateTime.month}.`;
    },

    toggleDetailHandler(e) {
      const programId = Number(e.target.getAttribute('data-id'));

      this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, programId);
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.episodes {
  &__title {
    display: inline-block;
    font-size: rem(15);
    color: $color-text-light;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    @include until($breakpoint-small) {
      font-size: rem(14);
    }
  }

  &__accordion-icon {
    padding-left: rem(20);

    svg {
      transition: all .25s;
    }
  }

  &__list {
    max-height: rem(164); /* 5 items visible - for accordion animation */
    overflow: hidden; /* for accordion animation */
  }

  &__item {
    display: flex;
    margin-bottom: rem(10);
    font-size: rem(13);
    line-height: 1.5;

    &:first-child {
      margin-top: rem(25); /* for accordion animation */

      @include until($breakpoint-small) {
        margin-top: rem(20);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-right {
    display: flex;
    flex-direction: column;

    @include from($breakpoint-small) {
      flex-direction: row;
    }
  }

  &__episode-date,
  &__episode-time,
  &__episode-series,
  &__episode-part {
    margin-right: rem(25);

    @include until($breakpoint-small) {
      margin-right: 0;
    }
  }

  &__episode-date,
  &__episode-time {
    display: inline-block;
    width: rem(40);
    text-align: right;
  }

  &__episode-date {
    margin-right: rem(5);
  }

  &__episode-part {
    @include until($breakpoint-small) {
      display: none;
    }

    &--mobile {
      display: none;
      margin: 0 0 0 rem(5);
      font-weight: 400;

      @include until($breakpoint-small) {
        display: inline-block;
      }
    }
  }

  &__episode-title {
    margin-right: 0;
    font-weight: 700;
    color: $color-text-light;
    text-decoration: none;
  }

  /* Accordion animation effect */
  &__accordion-effect-enter,
  &__accordion-effect-leave-to {
    max-height: 0;
  }

  &__accordion-effect-enter-active,
  &__accordion-effect-leave-active {
    transition: all .25s;
  }

  &--closed {
    .episodes__accordion-icon {
      svg {
        transition: all .25s;
        transform: rotate(180deg);
      }
    }
  }

  @include until($breakpoint-small) {
    &__episode-time {
      margin-right: rem(30);
    }
  }
}
</style>
