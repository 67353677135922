var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"date-strip__navigation",class:{
      'date-strip__navigation--fixed': _vm.headerPosition === 'fixed',
      'date-strip__navigation--sticked': _vm.headerPosition === 'sticky',
    }},[_c('div',{staticClass:"date-strip__navigation-left",on:{"click":function($event){return _vm.navigationArrowHandler('left')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('polyline',{attrs:{"points":"15 18 9 12 15 6"}})])]),_c('div',{staticClass:"date-strip__navigation-right",on:{"click":function($event){return _vm.navigationArrowHandler('right')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('polyline',{attrs:{"points":"9 18 15 12 9 6"}})])])]),_c('div',{ref:"dateStrip",staticClass:"grid__date-strip date-strip",class:{
    'date-strip--fixed': _vm.headerPosition === 'fixed',
    'date-strip--sticked': _vm.headerPosition === 'sticky',
  },on:{"scroll":_vm.onStripScroll}},[_c('div',{ref:"inner",staticClass:"date-strip__inner",on:{"mousedown":_vm.onMouseStart}},_vm._l((_vm.dates),function(ref){
  var weekday = ref.weekday;
  var date = ref.date;
  var timestamp = ref.timestamp;
  var timezoneOffset = ref.timezoneOffset;
  var isSelected = ref.isSelected;
return _c('div',{key:date,staticClass:"date-strip__column",class:{ 'date-strip__column--selected': isSelected },on:{"click":function () { return _vm.clickHandler(timestamp, timezoneOffset, date); }}},[_c('div',{staticClass:"date-strip__weekday"},[_vm._v(_vm._s(weekday))]),_c('div',{staticClass:"date-strip__date"},[_vm._v(" "+_vm._s(new Date().getDate() === date ? 'DNES' : (date + "."))+" ")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }