<template>
  <div v-if="favoriteChannels.length <= 0 && isVisible" class="grid__none-favorite">
    <h2>Nemáte žádnou oblíbenou stanici</h2>
    <div class="none-favorite__text">Přídejte stanice do oblíbených kliknutím na ikonu srdce</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {FITLER_FAVOURITE} from "@/config";

export default {
  name: 'GridNoneFavorite',

  computed: {
    ...mapGetters(['favoriteChannels', 'channelsFilter']),
    isVisible() {
      return this.channelsFilter === FITLER_FAVOURITE.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts.scss';

.grid {
  &__none-favorite {
    text-align: center;
    border-top: rem(2) solid $color-border-primary;

    h2 {
      margin-top: rem(35);
    }
    .none-favorite__text {
      margin-bottom: rem(35);
      padding-bottom: rem(175);
      background: url('~@/assets/images/png/none-favorite.png') no-repeat center rem(30);
      background-size: rem(161);
    }
  }
}
</style>
