<template>
  <section class="search-header">
    <div class="search-header__title-wrapper">
      <div class="search-header__back">
        <a class="search-header__back-link" href="/">
          <span class="icon icon--arrow-left icon--9x15"></span>Zpět na TV program
        </a>
      </div>
      <div class="search-header__title">
        <h2>Vyhledávání</h2>
      </div>
    </div>

    <div class="search-header__form-wrapper">
      <form action="#" class="search-header__form" @submit.prevent="sendSearchString">
        <input
          ref="searchInput"
          :placeholder="'Název stanice nebo pořadu...'"
          class="search-header__input"
          name="search"
          type="text"
          autocomplete="off"
          @input="onInputSearch"
          :value="searchString"
        />
        <span v-show="searchString" @click="clearSearch" class="search-header__remove"></span>
      </form>
      <SearchWhisperer :query="searchString" />
    </div>
  </section>
</template>

<script>
import SearchWhisperer from '@/components/Search/SearchWhisperer';

export default {
  name: 'SearchHeader',

  components: { SearchWhisperer },

  props: {
    searchStringFromUrl: String,
  },

  data() {
    return {
      searchString: this.$props.searchStringFromUrl,
    };
  },

  methods: {
    sendSearchString() {
      this.$emit('searchString', this.$refs.searchInput.value);

      if (this.$refs.searchInput.value !== '') {
        window.location.search = `search=${this.$refs.searchInput.value}`;
      }
    },

    onInputSearch({ target }) {
      this.searchString = target.value;
    },

    clearSearch() {
      this.searchString = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.search-header {
  &__title-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(20);

    @include until($breakpoint-small) {
      flex-direction: column;
      margin-bottom: rem(16);
      align-items: flex-start;
    }
  }

  &__back {
    position: absolute;
    left: 0;

    @include until($breakpoint-small) {
      position: relative;
      width: 100%;
      margin-bottom: rem(16);
    }
  }

  &__back-link {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: rem(13);
    color: $color-text-dark;
    text-decoration: none;

    .icon--arrow-left {
      margin-right: rem(6);
    }
  }

  &__title {
    @include until($breakpoint-small) {
      align-self: center;
    }
  }

  &__form-wrapper {
    position: relative;
    width: 100%;
  }

  &__input {
    width: 100%;
    height: rem(54);
    padding: 0 rem(56) 0 rem(20);
    font-weight: 700;
    font-size: rem(20);
    border: rem(2) solid $color-border-primary;
    border-radius: rem(10);
    //noinspection CssUnknownTarget
    background: url('../../assets/images/svg/search.svg') no-repeat calc(100% - 1.25rem) center;
    background-size: 18px;

    &:hover {
      border-color: $color-border-hover;
    }

    &:focus {
      outline: 0 none;
      border: rem(2) solid $color-highlight-primary;
      box-shadow: none;
    }

    @include until($breakpoint-small) {
      height: rem(40);
      padding: 0 rem(38) 0 rem(10);
      font-size: rem(16);
      border-radius: rem(6);
      background-position: calc(100% - 0.625rem) center;
    }
  }

  &__remove {
    cursor: pointer;
    top: rem(12);
    right: rem(12);
    width: rem(30);
    height: rem(30);
    position: absolute;
    display: block;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAjlJREFUaEPtmb8uRUEQhz+NSIRHoCY0qAihI9EJDZ6ChgegEQ/hX4cQD4BER4eeV6BSySSO3By7e3Z2Z+Pe5N7mFHdn9vftnJnd2dNDh/96Olw/XYD/jmA3Au0egQ1gFxgCnoAt4LGw6CngEJgA3oE94MQ3Z+gV2gSOaoZfwBpwVQhiCbgA+mr+ZSFPXXOGAF6BEYeRQKwAN8YQy8A50Ovw+wKMaQE+gX6PSGuIkHiRIFoGtAB3wFxgla0gmsSLhFtgQQsgyfTgCWnlKxciRrzMMf1TRP4wNO0Di8ClI6laHaUmti9h675XgeuUKlTZlIAwES8CmyJQAsJMvAZAxlpEwlS8FiAXwlx8CkAqRBHxqQBaCKlSruOBqtrkVCGfbWwNF3vX8cBkL4mtQj6ImMQOHZkkOsE633TeygWIfZ1cOrLF5+RAXZA2EibiLQE0kTATbw0QUyplztSzkzMdLHJAHMeKb608Jp2dBYBWvClELkCqeDOIHICO3shiVr6qNvIs1RRF9wOtFUAjvuqkYvaJpOqkfYVSxJdoin4XVAOQI74YRCyAhfgiEDEAluLNIZoASog3hQgBtNPF1ozvVjwEcA/MBhqK3Fu5ynXMhijXnPMuLSGAdrrc/QAGtQBypT3qMLJa+brrUCSegXEtgHxUOK4ZmTYjDkG+HXsdONMCyHgx3AGGf5Jo23dL3NR8K/6X4nEATAJvwL7v64x1R6bQaDe0aR+wm6mQpy5AoYWNdtuNQPRSFRr4DRzDtDELHD+vAAAAAElFTkSuQmCC");
    background-size: rem(20) rem(20);

    @include until($breakpoint-small) {
      top: rem(5);
      right: rem(5);
    }
  }

  /deep/ .whisperer {
    left: 0;
    right: 0;
  }
}
</style>
