<template>
  <transition name="toggle-detail">
    <div v-if="programDetailId !== null"
         id="program-detail"
         class="program-detail"
    >
      <Gallery :galleryData="galleryData"
               :isGalleryOpen="isGalleryOpen"
               @toggleGallery="toggleGallery"
               @updateGalleryData="updateGalleryData"
      />

      <div :class="{ 'program-detail__right-panel--gallery-open': isGalleryOpen, 'placeholder': placeholder }"
           class="program-detail__right-panel"
      >
        <div v-if="placeholder" class="program-detail__status status">
          <template v-if="!apiError">
            <span class="status__icon icon icon--spinner icon--60x60"></span>
            <p class="status__text">načítam data...</p>
          </template>
          <template v-if="apiError">
            <p class="status__text">Vyskytl se problém při načítání dat.</p>
            <p class="status__text">Prosím zkuste to znovu.</p>
          </template>
        </div>

        <section class="program-detail__content program-detail__content--header">
          <ProgramDetailHeader :programData="programData" @closeDetail="closeDetail"/>
        </section>

        <section v-if="galleryData.images.length > 0"
                 class="program-detail__content program-detail__content--gallery"
        >
          <ProgramDetailGallery
              :galleryImages="galleryData.images"
              :programDataTrailers="programDataTrailers"
              @toggleGallery="toggleGallery"
              @updateGalleryData="updateGalleryData"
          />
        </section>

        <section v-if="programData.description" class="program-detail__content">
          <p class="program-detail__description">
            {{ programData.description }}
          </p>
        </section>

        <section v-if="programDataEpisodes.length > 0" class="program-detail__content">
          <ProgramDetailEpisodes :programDataEpisodes="programDataEpisodes"/>
        </section>

        <section v-if="programArticles.length > 0" class="program-detail__content">
          <ProgramDetailArticles :programArticles="programArticles"/>
        </section>

        <!-- @todo: až bude pripravený BE
        <section class="program-detail__content program-detail__content&#45;&#45;actors" v-if="programDataActors.length > 0">
          <ProgramDetailActors :programDataActors="programDataActors" />
        </section> -->
      </div>
    </div>
  </transition>
</template>

<script>
import Gallery from '@/components/Gallery/Gallery';
import ProgramDetailHeader from '@/components/ProgramDetail/ProgramDetailHeader';
import ProgramDetailGallery from '@/components/ProgramDetail/ProgramDetailGallery';
import ProgramDetailEpisodes from '@/components/ProgramDetail/ProgramDetailEpisodes';
import ProgramDetailArticles from '@/components/ProgramDetail/ProgramDetailArticles';
// import ProgramDetailActors from '@/components/ProgramDetail/ProgramDetailActors'; @todo: až bude pripravený BE
import {mapGetters} from 'vuex';
import {TOGGLE_PROGRAM_DETAIL} from '@/store/actions/program-detail';
import {API} from '@/api';
import {analyticsData} from "@/utils";

export default {
  name: 'ProgramDetail',

  data() {
    return {
      placeholder: true,
      programData: {},
      programDataActors: [],
      programDataEpisodes: [],
      programDataTrailers: [],
      programArticles: [],
      apiError: false,
      isGalleryOpen: false,
      galleryData: {
        targetSrc: '',
        targetAlt: '',
        targetTitle: '',
        targetId: 0,
        images: [],
      },
    }
  },

  components: {
    Gallery,
    ProgramDetailHeader,
    ProgramDetailGallery,
    ProgramDetailEpisodes,
    ProgramDetailArticles,
    // ProgramDetailActors, @todo: až bude pripravený BE
  },

  computed: {
    ...mapGetters(['programDetailId']),
  },

  mounted() {
    this.openDetailFromHash();

    document.addEventListener('hashchange', () => {
      this.openDetailFromHash();
    });
  },

  methods: {
    openDetailFromHash() {
      const urlHash = window.location.hash;

      if (urlHash.indexOf('detail') !== -1) {
        const hashNumber = Number(urlHash.match(/\d+/)[0]);

        this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, hashNumber);
      }
    },

    closeDetail() {
      const title = this.programData.name;
      const timeDate = this.programData.startDateTime;

      /* GTM | Web & Apps */
      this.gtmCloseDetail(title, timeDate);

      this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, null);

      this.isGalleryOpen = false;
      this.galleryData.images = [];
      this.programArticles = [];
      this.programData = {};
      this.apiError = false;

      history.pushState({}, '', ' ');

      document.body.classList.remove('overlay-active');
      document.body.classList.add('overlay-inactive');

      this.placeholder = true;
    },

    closeDetailHandler(e) {
      if ((e?.target?.id === 'program-detail' || e?.keyCode === 27) && !this.isGalleryOpen) {
        this.closeDetail();
      }
    },

    updateProgramData(data) {
      /* All program data */
      this.programData = data.attributes;

      /* Reset gallery images */
      this.galleryData.images = [];

      if (data.attributes.imageUrl) {
        this.galleryData.images.push({
          src: data.attributes.imageUrl,
          id: 1,
        });

        if (data.attributes.galleryImages) {
          data.attributes.galleryImages.forEach((data, i) => {
            this.galleryData.images.push({
              src: data,
              id: i + 2,
            });
          })
        }
      } else if (data.attributes.galleryImages) {
        data.attributes.galleryImages.forEach((data, i) => {
          this.galleryData.images.push({
            src: data,
            id: i + 1,
          });
        })
      }

      /* Episodes data */
      if (data.attributes.nextEpisodes) {
        this.programDataEpisodes = data.attributes.nextEpisodes;
      }

      /* Program articles data */
      if (data.attributes.hasLoadArticlesFromCms) {
        this.getProgramArticles(data.attributes.name);
      }

      /* Actors data */
      if (data.attributes.actors) {
        this.programDataActors = data.attributes.actors;
      }

      /* Program trailers */
      if (data.attributes.trailers) {
        this.programDataTrailers = data.attributes.trailers;
      }
    },

    updateGalleryData(src, alt, title, id) {
      this.galleryData.targetSrc = src;
      this.galleryData.targetAlt = alt;
      this.galleryData.targetTitle = title;
      this.galleryData.targetId = Number(id);
    },

    toggleGallery(param) {
      this.isGalleryOpen = param;
    },

    async getProgram(id) {
      this.placeholder = true;

      const apiProgram = await API.programs.getProgram(id);

      await this.updateProgramData(apiProgram);

      if (apiProgram) {
        this.placeholder = false;

        const title = apiProgram.attributes.name;
        const timeDate = apiProgram.attributes.startDateTime;

        /* GTM | Web & Apps */
        this.gtmShowDetail(title, timeDate);

      } else if (apiProgram.status === 'error') {
        this.apiError = true;
      }
    },

    async getProgramArticles(programName) {
      this.programArticles = await API.programs.getProgramArticles([programName]);
    },

    setUrlHash(programId) {
      window.location.hash = `detail=${programId}`;
    },

    gtmShowDetail(title, timeDate) {
      analyticsData.send({
        'event': 'tvProgram_showDetail_open',
        'item': {
          'type': 'tvProgram',
          'title': title,
          'category': this.getStationName(),
          'timeDate': timeDate,
          'url': window.location.href
        }
      });
    },

    gtmCloseDetail(title, timeDate) {
      analyticsData.send({
        'event': 'tvProgram_showDetail_close',
        'item': {
          'type': 'tvProgram',
          'title': title,
          'category': this.getStationName(),
          'timeDate': timeDate,
          'url': window.location.href
        }
      });
    },

    getStationName() {
      let stationName = document.querySelector('.program-detail__station-img');
      if (stationName) {
        return stationName.title;
      }
    }
  },

  watch: {
    programDetailId(value) {
      /* Attach event listeners whie program detail is open */
      if (value !== null) {
        document.body.classList.remove('overlay-inactive');
        document.body.classList.add('overlay-active');

        /* Get data */
        this.getProgram(value);

        /* Set hash to URL */
        this.setUrlHash(value);

        /* Event listeners for close program detail */
        document.addEventListener('click', this.closeDetailHandler);
        document.addEventListener('keydown', this.closeDetailHandler);
      } else {
        /* Remove event listeners while program detail is closed */
        document.removeEventListener('click', this.closeDetailHandler);
        document.removeEventListener('keydown', this.closeDetailHandler);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/abstracts/abstracts';

$program-detail-max-width: rem(800);

.program-detail {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;

  &__right-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0);
    width: $program-detail-max-width;
    padding: rem(25) 0 rem(35);
    overflow-y: scroll;
    color: $color-text-light;
    background-color: $color-background-secondary;
    scrollbar-width: none; /* Firefox hide scroll bar */

    &::-webkit-scrollbar {
      display: none;
    }

    @include until($program-detail-max-width) {
      width: 100%;
    }

    @include until($breakpoint-small) {
      padding: rem(30) 0 rem(40);
    }

    &--gallery-open::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1000;
    }
  }

  &__content {
    margin: 0 rem(50) rem(30);
    padding-bottom: rem(30);
    border-bottom: rem(1) solid $color-border-secondary;

    @include until($breakpoint-small) {
      margin: 0 rem(10) rem(30);
    }

    &--header {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &--gallery {
      margin: 0 0 rem(20) rem(50);
      padding-bottom: 0;
      border-bottom: 0;

      @include until($breakpoint-small) {
        margin-left: rem(10);
      }
    }

    &--actors {
      margin-right: 0;
    }
  }

  &__description {
    font-size: rem(15);
    color: $color-text-grey;

    @include until($breakpoint-small) {
      font-size: rem(13);
    }
  }

  &__info {
    margin-bottom: rem(30);
    border-bottom: rem(1) solid $color-border-secondary;
  }

  &__gallery {
    margin-bottom: rem(20);
  }

  &__status {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    transform: translate(-50%, -50%);
  }

  &__status-text {
    margin-top: rem(8);
  }
}

// Animation for open & close program detail
.toggle-detail-enter-active,
.toggle-detail-leave-active {
  transition: all $animation-duration-toggle-detail ease-in-out;
}

.toggle-detail-enter,
.toggle-detail-leave-to {
  transform: translateX($program-detail-max-width);
}

// While API data is loading
.placeholder {
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-background-secondary;
    z-index: 1000;
    overflow-y: hidden;
  }
}
</style>
