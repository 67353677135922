/* Intersection observe polyfill. */
import 'intersection-observer';

import Vue from 'vue';
import Guide from '@/Guide.vue';
import ProgramDetail from '@/ProgramDetail.vue';
import Search from '@/Search.vue';
import Tips from '@/Tips.vue';
import store from '@/store';

import { createSlider } from '@/modules/slider';
import { currentProgressBar } from '@/modules/current-progress-bar';
import { currentContentManager } from '@/modules/current-content-manager';
import { button } from '@/modules/button';
import '@/styles/main.scss';

Vue.config.productionTip = false;

const guide = document.getElementById('guide');
const programDetail = document.getElementById('program-detail');
const search = document.getElementById('search');
const tips = document.getElementById('tips');

if (guide) {
  new Vue({
    store,
    render: (h) => h(Guide),
  }).$mount(guide);
}

if (programDetail) {
  new Vue({
    store,
    render: (h) => h(ProgramDetail),
  }).$mount(programDetail);
}

if (search) {
  new Vue({
    store,
    render: (h) => h(Search),
  }).$mount(search);
}

if (tips) {
  new Vue({
    store,
    render: (h) => h(Tips),
  }).$mount(tips);
}

/* INIT SLIDERS */
/* Current slider */
const sliderCurrent = document.querySelector(
  '.horizontal-strip__content-wrapper--js-slider-current'
);

if (sliderCurrent) {
  createSlider(sliderCurrent);
}

/* Program articles slider */
const sliderProgramArticles = document.querySelector(
  '.horizontal-strip__content-wrapper--js-slider-program-articles'
);

if (sliderProgramArticles) {
  const stepProgramArticleWidth = sliderProgramArticles.querySelectorAll('.slider-item--js')[1]
    .offsetWidth;

  createSlider(sliderProgramArticles, stepProgramArticleWidth);
}

/* Actor articles slider */
const sliderActorArticles = document.querySelector(
  '.horizontal-strip__content-wrapper--js-slider-actor-articles'
);

if (sliderActorArticles) {
  const stepActorArticleWidth = sliderActorArticles.querySelectorAll('.slider-item--js')[1]
    .offsetWidth;

  createSlider(sliderActorArticles, stepActorArticleWidth);
}

/* CURRENT PROGRESS BAR & DYNAMIC CONTENT */
const currentProgressBars = document.querySelectorAll('.program-small__progress--js');

if (currentProgressBars.length > 0) {
  currentProgressBar({
    container: currentProgressBars,
  });

  currentContentManager();
}

/* Button for load articles */
const wrapperArticles = document.querySelector('.vertical-strip__content');

if (wrapperArticles) {
	button(wrapperArticles);
}
