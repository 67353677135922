export const SET_CHANNELS_LIST = 'SET_CHANNELS_LIST';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_CHANNELS_DATA = 'SET_CHANNELS_DATA';
export const SET_INITIAL_TIMESTAMP = 'SET_INITIAL_TIMESTAMP';
export const SET_CURRENT_TIMESTAMP = 'SET_CURRENT_TIMESTAMP';
export const SET_DAYS_NUMBER = 'SET_DAYS_NUMBER';
export const SET_CHANNELS_FILTER = 'SET_CHANNELS_FILTER';
export const SET_CHANNELS_FILTER_EMPTY = 'SET_CHANNELS_FILTER_EMPTY';
export const SET_CHANNELS_CATEGORY = 'SET_CHANNELS_CATEGORY';
export const SET_USER_POSITION = 'SET_USER_POSITION';
export const SET_FAVORITE_CHANNEL = 'SET_FAVORITE_CHANNEL';
export const SET_RENDERING = 'SET_RENDERING';
