import { STATIONS, PROGRAMS, STATIONS_CATEGORY } from '@/api/constants';
import { makeRequest } from '@/utils';

export default {
  /**
   * @descriptionR Returns a list of all channels.
   */
  getChannels: async () => {
    try {
      const response = await makeRequest.get(STATIONS);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {};
    }
  },

  /**
   * @description Returns programs based on specified parameters.
   * @param {Object} arg
   * @param {Array} arg.channels
   * @param {Array} arg.dates
   */
  getPrograms: async ({ channels, dates }) => {
    try {
      const response = await makeRequest.post(PROGRAMS, {
        station_ids: channels,
        program_dates: dates,
      });
      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  /**
   * @descriptionR Returns a list of all channels category.
   */
  getChannelsCategory: async () => {
    try {
      const response = await makeRequest.get(STATIONS_CATEGORY);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {};
    }
  },
};
