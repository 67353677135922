export default {
	/**
	 * @description Send data to dataLayer
	 * @param {Object} dataObject
	 */
	send: (dataObject) => {
		window.didomiOnReady = window.didomiOnReady || [];
		window.didomiOnReady.push(function () {
			window.dataLayer.push(dataObject);
		});
	},
};
