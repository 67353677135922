<template>
  <div class="search">
    <div class="container container--small">
      <div class="search__content layout-bottom-space">
        <!-- Search header with search input field -->
        <SearchHeader :searchStringFromUrl="query"></SearchHeader>
      </div>

      <div class="search__content layout-bottom-space">
        <!-- Search results -->

        <div v-if="channels.length > 0" class="search__result-list search__result-list--channel">
          <h2>Nalezené TV stanice</h2>
          <div
            v-for="channel in channels"
            :key="channel.id"
            class="search__result-item"
            @click="openGridWithChannel(channel)"
          >
            <div class="search__result-logo">
              <img :src="channel.attributes.logoUrl" :title="channel.attributes.name" />
            </div>
            <div
              class="search__result-name"
              v-html="formatText(channel.attributes.name, query)"
            ></div>
            <div
              class="search__result-favorite"
              :class="{ 'search__result-favorite--favorited': isChannelFavorited(channel) }"
              @click.prevent="(event) => setFavoriteChannel(event, channel)"
            >
              <svg
                data-v-6fc82484=""
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="13"
                viewBox="0 0 24 24"
              >
                <path
                  data-v-6fc82484=""
                  d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div v-if="programs.length > 0" class="search__result-list search__result-list--program">
          <h2>Nalezené pořady</h2>
          <div
            v-for="program in programs"
            :key="program.id"
            class="search__result-item"
            @click="openProgramDetail(program.id)"
          >
            <div class="search__result-logo">
              <img
                :src="getStationLogo(program.attributes.station)"
                :title="getStationName(program.attributes.station)"
              />
            </div>
            <div class="search__result-meta">
              <div class="search__result-date">
                {{ getBroadcastDateTime(program.attributes.startDateTime) }}
              </div>
              <div
                class="search__result-name"
                v-html="formatText(program.attributes.name, query)"
              ></div>
            </div>
            <div class="search__result-type" v-if="program.attributes.contentType">
              <span>{{ program.attributes.contentType }}</span>
            </div>
          </div>
        </div>

        <!-- Search status -->
        <div class="search__status">
          <template v-if="this.status === 'loading'">
            <span class="status__icon icon icon--spinner-dark icon--60x60"></span>
            <p class="status__text">načítam data...</p>
          </template>
          <template v-if="this.status === 'error' && this.query">
            <p class="status__text">Vyskytl se problém při načítání dat.</p>
            <p class="status__text">Prosím zkuste to znovu.</p>
          </template>
          <template v-if="!query && this.status !== 'loading'">
            <p class="status__text">Zadejte hledaný výraz.</p>
          </template>
          <template v-if="query && this.status === 'no results'">
            <p class="status__text">Litujeme, ale nic jsme nenašli.</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchHeader from '@/components/Search/SearchHeader';
import {
  SET_CHANNELS,
  SET_CHANNELS_FILTER_EMPTY,
  SET_FAVORITE_CHANNEL,
} from '@/store/actions/guide';
import { TOGGLE_PROGRAM_DETAIL } from '@/store/actions/program-detail';
import { getUrlParams, getDate, hightlightText } from '@/utils';
import { API } from '@/api';
import { NAME_DAYS, PARAM_STATION } from '@/config';

export default {
  name: 'Search',

  components: {
    SearchHeader,
  },

  computed: {
    ...mapGetters(['favoriteChannels']),
  },

  data() {
    return {
      query: getUrlParams().search,
      stations: [],
      channels: [],
      programs: [],
      status: 'loading',
    };
  },

  async mounted() {
    this.getStations();

    const [channels, programs] = await Promise.all([
      API.search.getChannels(this.query),
      API.search.getPrograms(this.query),
    ])
      .catch(() => {
        this.status = 'error';
      })
      .finally(() => {
        this.status = '';
      });

    this.channels = channels;
    this.programs = programs;

    if (this.channels.length === 0 && this.programs.length === 0) {
      this.status = 'no results';
    }
  },

  methods: {
    async getStations() {
      const stations = await API.channels.getChannels();
      this.stations = stations;
    },

    openProgramDetail(id) {
      this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, parseInt(id, 10));
    },

    openGridWithChannel(channel) {
      if (window.location.pathname !== '/') {
        window.location.href = `/?${PARAM_STATION}=${channel.id}`;
        return;
      }
      this.$store.dispatch(SET_CHANNELS_FILTER_EMPTY);
      this.$store.dispatch(SET_CHANNELS, [channel]);
      this.$emit('onChannelsClick', true);
    },

    setFavoriteChannel(event, channel) {
      event.stopPropagation();

      this.$store.dispatch(SET_FAVORITE_CHANNEL, channel.id);
    },

    isChannelFavorited(channel) {
      return this.favoriteChannels.indexOf(channel.id) !== -1;
    },

    formatText(text) {
      return hightlightText(text, this.query);
    },

    getStationLogo(id) {
      return this.stations.find((station) => parseInt(station.id, 10) === id)?.attributes.logoUrl;
    },

    getStationName(id) {
      return this.stations.find((station) => parseInt(station.id, 10) === id)?.attributes.name;
    },

    getBroadcastDateTime(date) {
      const timestamp = Date.parse(date);
      const newStartDate = new Date(timestamp);
      const startDateTime = getDate.parsedDate(date);
      const nowDateTime = getDate.parsedDate(Date.now());
      const now = new Date();
      const tomorrowDateTime = getDate.parsedDate(now.setDate(now.getDate() + 1));
      let broadcastDay;

      /* Format day */
      if (startDateTime.day === nowDateTime.day && startDateTime.month === nowDateTime.month) {
        broadcastDay = 'Dnes';
      } else if (
        startDateTime.day === tomorrowDateTime.day &&
        startDateTime.month === tomorrowDateTime.month
      ) {
        broadcastDay = 'Zítra';
      } else {
        const broadcastDayNumber = newStartDate.getDay();

        broadcastDay = this.toCapitalize(NAME_DAYS[broadcastDayNumber]);
      }

      /* Start time */
      const startTime = getDate.formatTime(startDateTime);

      return `${broadcastDay} ${startDateTime.day}. ${startDateTime.month}. ${startDateTime.year} ${startTime}`;
    },

    toCapitalize(string) {
      return string[0].toUpperCase() + string.substring(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/abstracts/abstracts';

.search {
  &__result-list--channel {
    .search__result-name {
      flex: 1;
    }
  }

  &__result-list--program {
    margin-top: rem(80);

    @include until($breakpoint-small) {
      margin-top: rem(50);
    }
  }

  &__status {
    text-align: center;
  }

  &__result-item {
    display: flex;
    align-items: center;
    padding: rem(20) 0;
    border-bottom: rem(2) solid $color-border-primary;
    cursor: pointer;

    &:hover {
      .search__result-name {
        text-decoration: none;
      }
    }
  }

  &__result-logo {
    height: rem(36);
    width: rem(36);
    margin-right: rem(20);
  }

  &__result-meta {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    @include until($breakpoint-small) {
      flex-direction: column;
    }
  }

  &__result-date {
    width: rem(200);
    font-size: rem(13);
    font-weight: bold;
    margin-right: rem(10);
  }

  &__result-name {
    font-size: rem(15);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__result-type {
    padding-left: rem(20);

    span {
      padding: rem(3) rem(6);

      font-size: rem(11);
      font-weight: bold;
      text-transform: uppercase;

      background-color: $color-background-primary;
    }
  }

  &__result-favorite {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: rem(30);
    width: rem(30);
    z-index: 1;

    fill: transparent;
    stroke: $color-other-heart;
    stroke-width: 2px;

    &--favorited {
      fill: $color-other-heart;
    }
  }
}
</style>
