/* Current - load */
import {getDate} from "@/utils";
import {currentProgressBar} from "@/modules/current-progress-bar";
import {createSlider} from "@/modules/slider";
import {API_PROGRAMS_REFRESH, TOGGLE_PROGRAMS} from "@/config";
import {API} from '@/api';

export const currentContentManager = () => {
	const currentSectionOuterWrapper = document.querySelector('.horizontal-strip__content-wrapper--js-slider-current');
	const currentSectionInnerWrapper = currentSectionOuterWrapper.querySelector('.horizontal-strip__content--js');
	const visibleElement = currentSectionInnerWrapper.querySelector('.program-small');
	const elementHtml = visibleElement.outerHTML;

	let apiPrograms; // all incoming programs from API
	let apiSortedStations; // all incoming stations from API

	const groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			let key = obj[property];

			if (!acc[key]) acc[key] = [];

			acc[key].push(obj);
			return acc;
		}, {});
	};

	/* API */
	const getData = async () => {
		const getPrograms = await API.programs.getProgramsCurrent();
		const getStations = await API.channels.getChannels();

		Promise.all([getPrograms, getStations])
			.then(response => {
				apiPrograms = response[0];
				apiSortedStations = groupBy(response[1], 'id');

				loadContent();
			})
			.catch(error => {
				console.warn(error);
			});
	};

	/* Function for load new programs */
	const loadContent = () => {
		const visibleElements = Array.from(currentSectionInnerWrapper.querySelectorAll('.program-small'));
		let newProgramsId = []; // all programs to show

		const visibleProgramsId = visibleElements.map(element => {
			return element.querySelector('.program-small__link').getAttribute('data-id');
		});

		apiPrograms.forEach(element => {
			const apiProgramId = element.id;
			let newProgram = true;

			visibleProgramsId.forEach(id => {
				if (id === apiProgramId) newProgram = false;
			});

			if (newProgram) newProgramsId.push(apiProgramId);

			if (newProgramsId.indexOf(apiProgramId) >= 0) {
				currentSectionInnerWrapper.innerHTML += elementHtml;

				/* New values */
				const newProgramTitle = element.attributes.name;
				const newProgramStation = element.attributes.station;

				/* Elements */
				const addedElement = currentSectionInnerWrapper.querySelector('.program-small:last-child');
				const newProgramStationLogoEl = addedElement.querySelector('.program-small__station-image');
				const newProgramLinkEl = addedElement.querySelector('.program-small__link');
				const newProgramTimeEl = addedElement.querySelector('.program-small__time');
				const newProgramprogressEl = addedElement.querySelector('.program-small__progress--js');

				/* DATE FORMAT */
				const timestamp = Date.parse(element.attributes.startDateTime);
				const newStartDate = new Date(timestamp);

				/* Start time */
				const startDateTime = getDate.parsedDate(element.attributes.startDateTime);
				const startTime = getDate.formatTime(startDateTime);

				/* End time */
				const newEndDate = new Date(newStartDate.getTime() + element.attributes.lengthMinutes * 60000);
				const endDateTime = getDate.parsedDate(newEndDate);
				const endTime = getDate.formatTime(endDateTime);

				/* SET NEW ITEM (program) */
				/* Set name */
				if (newProgramTitle.length > 24) {
					newProgramLinkEl.innerText = newProgramTitle.slice(0, 24) + '...';
				} else {
					newProgramLinkEl.innerText = newProgramTitle;
				}

				/* Set data-id */
				newProgramLinkEl.setAttribute('data-id', element.id);

				/* Set station */
				const currentStation = apiSortedStations[newProgramStation][0];

				newProgramStationLogoEl.setAttribute('src', currentStation.attributes.logoUrl);
				newProgramStationLogoEl.setAttribute('alt', currentStation.attributes.name);

				/* Set title attribute */
				newProgramLinkEl.setAttribute('title', newProgramTitle);

				/* Set time & progress bar */
				newProgramTimeEl.innerText = `${startTime} - ${endTime}`;
				newProgramprogressEl.setAttribute('data-max', element.attributes.lengthMinutes);
				newProgramprogressEl.setAttribute('data-id', element.id);
			}
		});

		/* Set width of all progress bars */
		const currentProgressBars = currentSectionInnerWrapper.querySelectorAll('.program-small__progress--js');

		if (currentProgressBars.length > 0) {
			currentProgressBar({
				container: currentProgressBars,
			});
		}

		/* Reinit slider with new elements */
		createSlider(currentSectionOuterWrapper);
	};

	/* Function for show/delete programs */
	const toggleContent = () => {
		const visibleElements = Array.from(currentSectionInnerWrapper.querySelectorAll('.program-small'));

		visibleElements.forEach(element => {
			const progressBar = element.querySelector('.program-small__progress--js');
			const progressBarWidth = parseFloat(progressBar.style.width, 10);

			if (progressBarWidth >= 100) {
				element.remove();
			} else if (progressBarWidth <= 0) {
				element.style.display = 'none';
			} else {
				element.style.display = 'inline-block';
			}
		});

		/* Reinit slider with new elements */
		createSlider(currentSectionOuterWrapper);
	};

	/* Show/ hide programs */
	setInterval(() => {
		toggleContent();
	}, TOGGLE_PROGRAMS);

	/* Check for new programs */
	setInterval(() => {
		getData();
	}, API_PROGRAMS_REFRESH);
};
