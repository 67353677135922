<template>
  <div class="tips">
    <div class="tips__header">
      <h2 class="tips__title">Televizní tipy</h2>
      <ul class="tips__filter">
        <li
          v-for="[name, filter] in Object.entries(filters)"
          :key="name"
          :class="[filter.selected ? 'tips__filter-item--selected' : '', 'tips__filter-item']"
          @click="onFilterClick(filter)"
        >
          {{ filter.label }}
        </li>
      </ul>
    </div>
    <div
      class="tips__list"
      ref="tipsList"
      @scroll="setNavigationVisibility"
      @mouseenter="setNavigationVisibility"
    >
      <div class="tips__list-navigation">
        <div
          v-if="navigation.left"
          class="tips__list-navigation--left"
          @click="onNavigationClick('prev')"
        ></div>
        <div
          v-if="navigation.right"
          class="tips__list-navigation--right"
          @click="onNavigationClick('next')"
        ></div>
      </div>

      <div class="tips__list-inner" @mousedown="onMouseStart" ref="tipsInner">
        <div
          class="tips__item program-large"
          v-for="{ id, attributes } in tips"
          :key="id"
          @click="onProgramClick(id)"
          ondragstart="return false;"
        >
          <div class="program-large__inner-wrapper">
            <div class="program-large__image-wrapper">
              <img class="program-large__image" :src="attributes.imageUrl" :alt="attributes.name" />
            </div>
            <div class="program-large__info">
              <h3 class="program-large__title">{{ attributes.name }}</h3>
              <div class="program-large__meta">
                <span class="program-large__weekday">{{
                  getDayOfWeek(attributes.startDateTime)
                }}</span>
                <span class="program-large__time">{{ formatTime(attributes.startDateTime) }}</span>
                <span class="program-large__station">
                  <img :src="getChannelImage(attributes.station)" loading="lazy" />
                </span>
              </div>
              <div class="program-large__type">
                <span v-if="attributes.contentType">{{ attributes.contentType }}</span>
                <span
                  v-if="attributes.genre && !isSame(attributes.genre, attributes.contentType)"
                  >{{ attributes.genre }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/api';
import { dragger, getDate } from '@/utils';
import { TOGGLE_PROGRAM_DETAIL } from '@/store/actions/program-detail';
import { NAME_DAYS } from '@/config';
import {mapGetters} from "vuex";

/**
 * @param {Number} [dayOfWeek] [1: Monday ... 7: Sunday]
 * @param {Date} [currentDay]
 * @return {String}
 */
const getFormateDate = (dayOfWeek, currentDay = new Date()) => {
  const today = currentDay;
  const day = currentDay.getDay();

  let targetDate = currentDay;

  if (dayOfWeek !== undefined) {
    const diff = currentDay.getDate() - day + (day == 0 ? -6 : dayOfWeek);
    targetDate = new Date(today.setDate(diff));
  }

  const targetDay = `0${targetDate.getDate()}`.slice(-2);
  const targetMonth = `0${targetDate.getMonth() + 1}`.slice(-2);

  return `${targetDate.getFullYear()}-${targetMonth}-${targetDay}`;
};

export default {
  name: 'Tips',

  data() {
    return {
      filters: {
        today: {
          label: 'Tipy na večer',
          selected: true,
          params: [
            {
              date_start: getFormateDate(),
              time_from: '20:00:00',
              time_to: '23:59:59',
            },
          ],
        },
        weekend: {
          label: 'Tipy na víkend',
          selected: false,
          params: [
            {
              date_start: getFormateDate(6),
              time_from: '20:00:00',
              time_to: '23:59:59',
            },
            {
              date_start: getFormateDate(7),
              time_from: '20:00:00',
              time_to: '23:59:59',
            },
          ],
        },
        sunday: {
          label: 'Tipy na neděli dopoledne',
          selected: false,
          params: [
            {
              date_start: getFormateDate(7),
              time_from: '08:00:00',
              time_to: '12:00:00',
            },
          ],
        },
        favorite: {
          label: 'Tipy na večer z oblíbených stanic',
          selected: false,
          params: [
            {
              date_start: getFormateDate(),
              time_from: '20:00:00',
              time_to: '23:59:59',
            },
          ],
        },
      },
      tips: [],
      channels: [],
      navigation: {
        left: false,
        right: false,
      },
    };
  },

  async mounted() {
    const channels = await API.channels.getChannels();
    this.channels = channels;

    this.onFilterClick(this.filters.today);
  },

  computed: {
    ...mapGetters(['favoriteChannels']),
  },

  methods: {
    getList() {
      return this.$refs.tipsList;
    },

    getListInner() {
      return this.$refs.tipsInner;
    },

    getChannelImage(id) {
      const channel = this.channels.find((channel) => parseInt(channel.id, 10) === id);
      return channel.attributes.logoUrl;
    },

    formatTime(stringDate) {
      const startDate = new Date(stringDate);
      const endDate = new Date(stringDate);
      endDate.setMinutes(endDate.getMinutes() + 30);

      const startTime = getDate.parsedDate(startDate);
      const endTime = getDate.parsedDate(endDate);

      const startHours = `0${startTime.hours}`.slice(-2);
      const startMinutes = `0${startTime.minutes}`.slice(-2);
      const endHours = `0${endTime.hours}`.slice(-2);
      const endMinutes = `0${endTime.minutes}`.slice(-2);

      return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
    },

    getDayOfWeek(stringDate) {
      const date = new Date(stringDate);
      const day = NAME_DAYS[date.getDay()].substr(0, 2);

      return `${day},`;
    },

    async onFilterClick(currentFilter) {
      if (this.favoriteChannels.length > 0) {
        this.filters.favorite.params[0].stations = this.favoriteChannels.toString();
      } else  {
        delete this.filters.favorite;
      }

      Object.values(this.filters).forEach((filter) => {
        filter !== currentFilter ? (filter.selected = false) : (filter.selected = true);
      });

      const tips = await Promise.all(
        currentFilter.params.map((day) => {
          return API.programs.getProgramTips(day);
        })
      );

      this.tips = tips.reduce((acc, curr) => [...acc, ...curr], []);

      if (this.navigation.left) {
        this.getList().scroll({
          left: 0,
          behavior: 'smooth',
        });
      }
    },

    onProgramClick(programId) {
      if (this.originalScroll === this.getList().scrollLeft) {
        this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, parseInt(programId, 10));
      }
    },

    onNavigationClick(direction) {
      const itemWidth = this.getListInner().clientWidth / this.tips.length;
      const scrollLeft = this.getList().scrollLeft;
      let scrollPositon;

      if (direction === 'prev') {
        scrollPositon = scrollLeft - itemWidth;
      } else {
        scrollPositon = scrollLeft + itemWidth;
      }

      this.getList().scroll({
        left: scrollPositon,
        behavior: 'smooth',
      });
    },

    setNavigationVisibility() {
      const sideOffset = 10;

      if (this.getList().scrollLeft < sideOffset) {
        this.navigation.left = false;
      } else {
        this.navigation.left = true;
      }

      if (
        this.getList().scrollLeft >
        this.getListInner().clientWidth - this.getList().clientWidth - sideOffset
      ) {
        this.navigation.right = false;
      } else {
        this.navigation.right = true;
      }
    },

    dragCallback(distance) {
      const maxTranslate = this.getListInner().clientWidth - this.getList().clientWidth;

      const scrollPosition = Math.min(
        maxTranslate,
        Math.max(0, this.getList().scrollLeft + distance)
      );
      this.getList().scrollLeft = scrollPosition;
    },

    onMouseStart(event) {
      this.originalScroll = this.getList().scrollLeft;
      dragger.mouseDrag(event, this.dragCallback);
    },

    isSame(str1, str2) {
      return str1.toLowerCase() === str2.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.tips {
  margin-bottom: rem(80);

  @include until($breakpoint-small) {
    margin-bottom: rem(10);
  }

  &__header {
    display: flex;
    align-items: flex-end;
    margin-left: rem(25);

    @include until($breakpoint-small) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: rem(15);
    }
  }

  h2 {
    font-size: rem(25);
  }

  &__filter {
    display: flex;
    margin-left: rem(32);

    @include until($breakpoint-small) {
      max-width: 100%;
      margin-left: 0;
      overflow-x: scroll;
      padding-right: rem(15);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-item {
      display: flex;
      font-size: rem(13);
      font-weight: bold;
      line-height: 1;
      margin-top: rem(5);
      margin-right: rem(10);
      margin-bottom: rem(5);
      padding: rem(8) rem(15);
      border: rem(2) solid $color-border-primary;
      border-radius: rem(6);
      white-space: nowrap;
      cursor: pointer;
      transition: border-color 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);

      &:last-child {
        margin-right: rem(0);
      }

      &:hover:not(.tips__filter-item--selected) {
        border-color: $color-border-hover;
      }
    }

    &-item--selected {
      background-color: $color-highlight-primary;
      color: $color-white;
      border-color: $color-highlight-primary;
    }
  }

  &__list {
    overflow-x: scroll;
    scrollbar-width: none;
    height: rem(400);

    @include until($breakpoint-small) {
      height: rem(336);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      .tips__list-navigation {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__list-inner {
    display: inline-flex;
    margin-top: rem(30);
    overflow-x: scroll;
    padding: 0 rem(15);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include until($breakpoint-small) {
      padding: 0 rem(5);
    }
  }

  &__item {
    margin: 0 rem(10);
  }

  &__list-navigation {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: rem(120);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease-out;
  }

  &__list-navigation--left,
  &__list-navigation--right {
    position: absolute;
    width: rem(51);
    height: rem(102);
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1 !important;
    }
  }

  &__list-navigation--right {
    right: 0;
  }

  &__list-navigation--left {
    background-image: url('~@/assets/images/svg/previous.svg');
  }

  &__list-navigation--right {
    background-image: url('~@/assets/images/svg/next.svg');
  }
}
</style>
