import {
  SET_CHANNELS,
  SET_CHANNELS_CATEGORY,
  SET_CHANNELS_FILTER,
  SET_CHANNELS_FILTER_EMPTY,
  SET_CHANNELS_LIST,
  SET_CURRENT_TIMESTAMP,
  SET_DAYS_NUMBER,
  SET_FAVORITE_CHANNEL,
  SET_INITIAL_TIMESTAMP,
  SET_PROGRAMS,
  SET_RENDERING,
  SET_USER_POSITION,
} from '@/store/actions/guide';
import { storage } from '@/storage';
import { DAYS_NUMBER, DAYS_NUMBER_BACK, FITLER_FAVOURITE } from '@/config';
import { getDate } from '@/utils';

const STORAGE_CHANNELS_FILTERS = 'channelsFilter';
const STORAGE_FAVORITE_CHANNELS = 'favoriteChannels';

const userSettings = storage.get();
const { day, month, year } = getDate.parsedDate();
const initialTimestamp = getDate.toMinutes(`${year}/${month}/${day}`) - 24 * 60 * DAYS_NUMBER_BACK;
const currentTimestamp = getDate.toMinutes();
const favoriteChannels = userSettings?.[STORAGE_FAVORITE_CHANNELS] ?? [];

const state = {
  channelsList: [],
  channels: [],
  programs: {},
  initialTimestamp,
  currentTimestamp,
  daysNumber: DAYS_NUMBER,
  userPosition: {},
  channelsFilter: {},
  channelsCategory: [],
  favoriteChannels,
  isRendering: false,
};

const getters = {
  channelsList: (state) => state.channelsList,
  channels: (state) => state.channels,
  programs: (state) => state.programs,
  initialTimestamp: (state) => state.initialTimestamp,
  currentTimestamp: (state) => state.currentTimestamp,
  daysNumber: (state) => state.daysNumber,
  userPosition: (state) => state.userPosition,
  channelsFilter: (state) => state.channelsFilter,
  channelsCategory: (state) => state.channelsCategory,
  favoriteChannels: (state) => state.favoriteChannels,
  isRendering: (state) => state.isRendering,
};

const actions = {
  [SET_CHANNELS_LIST]: ({ commit }, payload) => {
    commit(SET_CHANNELS_LIST, payload);
  },
  [SET_CHANNELS]: ({ commit }, payload) => {
    commit(SET_CHANNELS, payload);
  },
  [SET_PROGRAMS]: ({ commit, state }, payload) => {
    let currentPrograms = { ...state.programs };

    if (!state.userPosition.dates) return;

    // Get current day.
    const date = state.userPosition.dates[1]
      ? state.userPosition.dates[1]
      : state.userPosition.dates[0];

    // Only if the program contains more than x days
    if (Object.keys(currentPrograms).length > 5) {
      // Sort days by how far they are from the current day and get only closest days
      const nearestDays = Object.keys(currentPrograms)
        .sort((dateA, dateB) => {
          const parsedDate = Date.parse(date);
          return (
            Math.abs(Date.parse(dateA) - parsedDate) - Math.abs(Date.parse(dateB) - parsedDate)
          );
        })
        .splice(0, 5);

      // Clean the program from all distant days.
      currentPrograms = Object.keys(currentPrograms).reduce((acc, curr) => {
        if (nearestDays.includes(curr)) {
          acc[curr] = currentPrograms[curr];
        }
        return acc;
      }, {});
    }

    const programs = payload.reduce((acc, program) => {
      const { date, station, lengthMinutes, startDateTime } = program.attributes;
      const minutes = getDate.toMinutes(startDateTime);
      program.attributes.minutes = minutes;
      program.attributes.width = lengthMinutes ? `${lengthMinutes * 5}px` : 'auto';
      program.attributes.left = `${(minutes - state.initialTimestamp) * 5 - 2}px`;
      if (!acc[date]) acc[date] = {};
      if (!acc[date][station]) acc[date][station] = [];
      acc[date][station].push(program);
      return acc;
    }, currentPrograms);

    commit(SET_PROGRAMS, programs);
  },
  [SET_INITIAL_TIMESTAMP]: ({ commit }, payload) => {
    commit(SET_INITIAL_TIMESTAMP, payload);
  },
  [SET_CURRENT_TIMESTAMP]: ({ commit }, payload) => {
    commit(SET_CURRENT_TIMESTAMP, payload);
  },
  [SET_DAYS_NUMBER]: ({ commit }, payload) => {
    commit(SET_DAYS_NUMBER, payload);
  },
  [SET_USER_POSITION]: ({ commit }, payload) => {
    commit(SET_USER_POSITION, payload);
  },
  [SET_CHANNELS_FILTER]: ({ commit, getters }, payload) => {
    storage.set({ [STORAGE_CHANNELS_FILTERS]: payload });
    commit(SET_CHANNELS_FILTER, payload);

    let channels;

    if (payload === FITLER_FAVOURITE.id) {
      channels = getters.channelsList.filter((channel) =>
        getters.favoriteChannels.includes(channel.id)
      );
    } else {
      channels = getters.channelsList.filter(
        (channel) => channel?.attributes?.categories.includes(parseInt(payload, 10))
      );
    }

    commit(SET_CHANNELS, channels);
  },
  [SET_CHANNELS_FILTER_EMPTY]: ({ commit }) => {
    commit(SET_CHANNELS_FILTER, '-1');
  },
  [SET_CHANNELS_CATEGORY]: ({ commit }, payload) => {
    commit(SET_CHANNELS_CATEGORY, payload);
  },
  [SET_FAVORITE_CHANNEL]: ({ commit, getters }, payload) => {
    let favoriteChannels = [...getters.favoriteChannels];
    let isExist = favoriteChannels.indexOf(payload);

    isExist < 0 ? favoriteChannels.push(payload) : favoriteChannels.splice(isExist, 1);

    storage.set({ [STORAGE_FAVORITE_CHANNELS]: favoriteChannels });
    commit(SET_FAVORITE_CHANNEL, favoriteChannels);
  },
  [SET_RENDERING]: ({ commit }, payload) => {
    commit(SET_RENDERING, payload);
  },
};

const mutations = {
  [SET_CHANNELS_LIST]: (state, payload) => {
    state.channelsList = payload;
  },
  [SET_CHANNELS]: (state, payload) => {
    state.channels = payload;
  },
  [SET_PROGRAMS]: (state, payload) => {
    state.programs = payload;
  },
  [SET_INITIAL_TIMESTAMP]: (state, payload) => {
    state.initialTimestamp = payload;
  },
  [SET_CURRENT_TIMESTAMP]: (state, payload) => {
    state.currentTimestamp = payload;
  },
  [SET_DAYS_NUMBER]: (state, payload) => {
    state.daysNumber = payload;
  },
  [SET_USER_POSITION]: (state, payload) => {
    state.userPosition = payload;
  },
  [SET_CHANNELS_FILTER]: (state, payload) => {
    state.channelsFilter = payload;
  },
  [SET_CHANNELS_CATEGORY]: (state, payload) => {
    state.channelsCategory = payload;
  },
  [SET_FAVORITE_CHANNEL]: (state, payload) => {
    state.favoriteChannels = payload;
  },
  [SET_RENDERING]: (state, payload) => {
    state.isRendering = payload;
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
