<template>
  <transition name="fade">
    <div
      v-if="visibility.left || visibility.right"
      :style="styles"
      class="grid__navigation-arrow"
      ref="gridNavigationArrow"
    >
      <div
        v-if="visibility.left"
        @click="arrowClickHandler('left')"
        class="grid__navigation-left"
      ></div>
      <div
        v-if="visibility.right"
        @click="arrowClickHandler('right')"
        class="grid__navigation-right"
      ></div>
    </div>
  </transition>
</template>

<script>
import { MINUTE_SIZE_IN_PIXELS } from '@/config';

export default {
  name: 'GridTimeStrip',

  data() {
    return {
      styles: '',
    };
  },

  props: {
    visibility: Object, // {left: boolean, right: boolean}
    position: Object, // {position: [absolute|fixed], translate: number}
    doGridTranslate: Function,
    translate: Number,
  },

  watch: {
    position: {
      handler() {
        if (!this.position) return;
        const { position, translate } = this.position;
        this.styles = { position: position, transform: `translateY(${translate}px)` };
      },
      deep: true,
    },
  },

  methods: {
    arrowClickHandler(direction) {
      if (direction === 'left') {
        this.doGridTranslate(this.translate - 60 * MINUTE_SIZE_IN_PIXELS);
      } else {
        this.doGridTranslate(this.translate + 60 * MINUTE_SIZE_IN_PIXELS);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.grid__navigation-arrow {
  position: absolute;
  top: calc(50% - 51px);
  z-index: 9;
  width: 100%;
}

.grid__navigation-left,
.grid__navigation-right {
  position: absolute;
  width: rem(51);
  height: rem(102);
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 1 !important;
  }
}

.grid__navigation-left {
  left: rem(227);
  background-image: url('~@/assets/images/svg/previous.svg');
}

.grid__navigation-right {
  right: 0;
  background-image: url('~@/assets/images/svg/next.svg');
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
