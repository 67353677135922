<template>
  <div class="grid__time-pointer time-pointer" :style="{ width: `${pointerPosition}px` }"></div>
</template>

<script>
import {mapGetters} from 'vuex';
import {MINUTE_SIZE_IN_PIXELS} from '@/config';

export default {
  name: 'GridTimePointer',

  computed: {
    ...mapGetters(['initialTimestamp', 'currentTimestamp']),
    pointerPosition() {
      return (this.currentTimestamp - this.initialTimestamp) * MINUTE_SIZE_IN_PIXELS;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.time-pointer {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color-other-progress, 0.1);
  border-right: rem(2) solid $color-other-progress;
  pointer-events: none;
  z-index: 5;
}
</style>
