/**
 * @description Return url params in object
 * @returns { Object }
 */
export default () => {
  const { search } = window.location;

  if (search.length === 0) return {};

  const hashes = search
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/\+/g, ' ')
    .slice(search.indexOf('?') + 1)
    .split('&');

  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return { ...params, [key]: decodeURIComponent(val) };
  }, {});
};
