export default {
  /**
   * @description Converts time to minutes.
   * @param {String} stringDate
   * @return {Number}
   */
  toMinutes: (stringDate = new Date()) => {
    let strDate = stringDate;
    if (typeof stringDate === 'string') {
      strDate = stringDate.replace(/([+-]\d{2})(\d{2})$/g, '$1:$2');
    }
    return Math.floor(Date.parse(strDate) / 1000 / 60);
  },

  /**
   * @Returns Converts minutes to time.
   * @param {Number} minutes
   * @return {string}
   */
  toTime: (minutes) => {
    return {
      hours: (minutes / 60) ^ 0,
      minutes: minutes % 60,
    };
  },

  /**
   * @description Format minutes & hours from object to human format.
   * @param {Object} dateTimeObject
   * @param {Number} dateTimeObject.hours
   * @param {Number} dateTimeObject.minutes
   * @return {string}
   */
  formatTime: (dateTimeObject) => {
    const minutes =
      dateTimeObject.minutes < 10 ? '0' + dateTimeObject.minutes : dateTimeObject.minutes;
    const hours = dateTimeObject.hours < 10 ? '0' + dateTimeObject.hours : dateTimeObject.hours;

    return `${hours}:${minutes}`;
  },

  /**
   * @description Returns a parsed date object.
   * @param {String} stringDate
   * @return {{String: Number}}
   */
  parsedDate: (stringDate) => {
    const date = stringDate ? new Date(stringDate) : new Date();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return {
      minutes,
      hours,
      day,
      month,
      year,
    };
  },

  /**
   * @description Returns the minutes of today by hour.
   * @param {Number} hour 0 - 23
   * @return {Number}
   */
  getTodayMinutsByHour(hour) {
    const today = new Date();
    today.setHours(hour, 0, 0, 0);
    return today.getTime() / 1000 / 60;
  },

  /**
   * @description Returns if day is today.
   * @param {dateTimeObject}
   * @return {Number}
   */
  isToday(date) {
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  },
};
