<template>
  <div class="program-detail__header">
    <h2 class="program-detail__title">
      {{ programData.name }}
    </h2>
    <div class="program-detail__meta">
      <span class="program-detail__station">
        <img class="program-detail__station-img"
             :src="stationLogo"
             :alt="stationName"
             :title="stationName"
        >
      </span>
      <time class="program-detail__day-time">
        <span class="program-detail__day">{{ broadcastDay }} </span>
        <span class="program-detail__time">{{ startTime }} - {{ endTime }}</span>
      </time>
      <time class="program-detail__duration" v-if="duration.hours > 0 || duration.minutes > 0 ">
        {{ duration.hours > 0 ? duration.hours + ' hod.' : '' }}
        {{ duration.minutes > 0 ? duration.minutes + ' min.' : '' }}
      </time>
      <span class="program-detail__genre" v-if="programData.genre">{{ programData.genre }}</span>
      <span class="program-detail__type" v-else><span v-if="programData.contentType">{{ programData.contentType }}</span></span>
    </div>
    <!-- Close icon -->
    <span class="program-detail__close icon icon--close"
          @click="closeDetailHandler"
    ></span>
  </div>
</template>

<script>
import {getDate} from '@/utils';
import {API} from "@/api";

export default {
  name: 'ProgramDetailHeader',

  props: {
    programData: Object,
  },

  data() {
    return {
      broadcastDay: '',
      startTime: '',
      endTime: '',
      duration: {},
      stations: [],
      stationName: '',
      stationLogo: '',
    }
  },

  methods: {
    closeDetailHandler() {
      this.$emit('closeDetail');
    },

    formatTime(datetime) {
      const minutes = datetime.minutes < 10 ? '0' + datetime.minutes : datetime.minutes;

      return `${datetime.hours}:${datetime.minutes === 0 ? '00' : minutes}`;
    },

    setStation() {
      /* Set station variables */
      this.stations.forEach(station => {
        if (this.programData.station === Number(station.id)) {
          this.stationName = station.attributes.name;
          this.stationLogo = station.attributes.logoUrl;
        }
      });
    },

    async getStations() {
      this.stations = await API.channels.getChannels();
    },
  },

  mounted() {
    /* Get stations */
    this.getStations();
  },

  watch: {
    programData(data) {
      if (data) {
        /* DATE FORMAT */
        const timestamp = Date.parse(data.startDateTime);
        const newStartDate = new Date(timestamp);
        const startDateTime = getDate.parsedDate(this.programData.startDateTime);

        /* Broadcast day */
        const nowDateTime = getDate.parsedDate(Date.now());
        const now = new Date();
        const tomorrowDateTime = getDate.parsedDate(now.setDate(now.getDate() + 1));

        if (startDateTime.day === nowDateTime.day && startDateTime.month === nowDateTime.month) {
          this.broadcastDay = 'Dnes';
        } else if (startDateTime.day === tomorrowDateTime.day && startDateTime.month === tomorrowDateTime.month) {
          this.broadcastDay = 'Zítra';
        } else {
          this.broadcastDay = `${startDateTime.day}. ${startDateTime.month}.`;
        }

        /* Start time */
        this.startTime = this.formatTime(startDateTime);

        /* End time */
        const newEndDate = new Date(newStartDate.getTime() + this.programData.lengthMinutes * 60000);
        const endDateTime = getDate.parsedDate(newEndDate);

        this.endTime = this.formatTime(endDateTime);

        /* Duration */
        this.duration = getDate.toTime(this.programData.lengthMinutes);

        /* Set station logo */
        this.setStation();
      }
    },

    stations() {
      /* Set station logo */
      this.setStation();
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.program-detail {
  &__title {
    margin-bottom: rem(5);
    padding-right: rem(24);
    font-size: rem(25);
    line-height: 1.2;

    @include until($breakpoint-small) {
      padding-right: rem(56);
      font-size: rem(18);
    }
  }

  &__meta {
    display: flex;
    align-items: center;
  }

  &__station {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(40);
    height: rem(40);
    margin-right: rem(10);
    border-radius: rem(2);
    background-color: $color-background-light;
  }

  &__station-img {
    max-width: rem(30);
    height: auto;
    color: $color-text-dark;
    font-size: rem(12);
  }

  &__genre {
    padding: rem(2) rem(5);
    font-size: rem(11);
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    color: $color-text-dark;
    background-color: $color-background-primary;
    border-radius: rem(2);
  }

  &__type {
    span {
      font-size: rem(11);
      font-weight: 700;
      background-color: $color-border-separator;
      text-transform: uppercase;
      border-radius: rem(3);
      padding: rem(1) rem(6);
      color: $color-text-dark;
    }

  }

  &__day-time,
  &__duration {
    margin-right: rem(20);
    font-size: rem(13);

    @include until($breakpoint-small) {
      margin-right: rem(10);
      font-size: rem(11);
    }
  }

  &__time {
    white-space: nowrap;
  }

  &__duration {
    white-space: nowrap;
    color: $color-text-grey;
  }

  &__close {
    position: absolute;
    top: rem(20);
    right: rem(20);

    @include until($breakpoint-small) {
      top: rem(10);
      right: rem(10);
    }
  }
}
</style>
