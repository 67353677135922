/* Current in TV - progress bar */
import makeRequest from '@/utils/make-request';
import {getDate} from "@/utils";
import {PROGRESS_BARS_REFRESH} from "@/config";
import {PROGRAMS_CURRENT} from '@/api/constants';

export const currentProgressBar = currentProgressBars => {
	let progressBars = Array.from(currentProgressBars.container);
	let programs;

	/* API */
	makeRequest.get(PROGRAMS_CURRENT)
		.then(response => {
			programs = response.data.data;

			setProgressBars();
		})
		.catch(error => {
			console.warn(error);
	});

	/* Set progress bars */
	const setProgressBars = () => {
		progressBars.forEach(progressBar => {
			const programId = progressBar.getAttribute('data-id');

			programs.forEach(program => {
				if (programId === program.id) {
					// Timestamps
					const startTimestamp = Date.parse(program.attributes.startDateTime);
					const nowTimestamp = Date.now();
					const passedMiliseconds = nowTimestamp - startTimestamp;

					// Passed & duration minutes
					const passedMinutes = getDate.toMinutes(new Date(passedMiliseconds));
					const maxMinutes = progressBar.getAttribute('data-max');
					const progressWidth = passedMinutes * 100 / maxMinutes;

					/* Set width */
					if (progressWidth > 0) {
						progressBar.style.width = `${progressWidth}%`;
					} else if (passedMinutes === 0) {
						progressBar.style.width = '0.1%';
					} else {
						progressBar.style.width = '0';
					}
				}
			});
		});
	};

	/* Set new width of progress-bar */
	setInterval(() => {
		setProgressBars(programs);
	}, PROGRESS_BARS_REFRESH);
};
