<template>
  <div>
    <transition name="time-label-fade">
      <div
        class="grid__time-label time-label"
        v-if="userPosition.inPast || userPosition.inFuture"
        :class="{
          'time-label--past': userPosition.inFuture,
          'time-label--future': userPosition.inPast,
          'time-label--fixed': headerPosition === 'fixed',
          'time-label--sticked': headerPosition === 'sticky',
        }"
        @click="translateGridToCurrent"
      >
        <span class="time-label--text">Zpět na </span>{{ currentTime }}
      </div>
    </transition>

    <transition name="time-label-fade">
      <div
        v-if="showPrimetimeLabel"
        class="grid__time-label time-label time-label--primetime"
        :class="{
          'time-label--past': userPosition.primetime === 'inPast',
          'time-label--future': userPosition.primetime === 'inFuture',
          'time-label--fixed': headerPosition === 'fixed',
          'time-label--sticked': headerPosition === 'sticky',
        }"
        @click="translateGridToPrimetime"
      >
        <span class="time-label--text">Dnes večer</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDate } from '@/utils';

export default {
  name: 'GridTimeLabels',

  props: {
    translateGridToCurrent: Function,
    translateGridToPrimetime: Function,
    headerPosition: String,
  },

  computed: {
    ...mapGetters(['currentTimestamp', 'userPosition']),
    currentTime() {
      const { minutes, hours } = getDate.parsedDate(this.currentTimestamp * 60 * 1000);
      return `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    },

    showPrimetimeLabel() {
      const { primetime, inPast } = this.userPosition;
      if (primetime === null) return false;
      if (primetime === 'inPast') return false;
      if (primetime === 'inFuture' && inPast) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.time-label {
  position: absolute;
  padding: rem(12) rem(13);
  margin-top: rem(-5);
  z-index: 9;
  font-size: rem(12);
  background-color: $color-other-label-green;
  color: $color-text-light;
  border-radius: rem(3);
  text-decoration: underline;
  cursor: pointer;
  top: rem(175);

  @include until($breakpoint-small) {
    top: rem(158);
  }

  @include from($breakpoint-header) {
    top: rem(58);
  }

  &--fixed {
    position: fixed;
    z-index: 12;
    top: rem(56);

    @include until($breakpoint-small) {
      top: rem(157);
    }
  }

  &--sticked {
    position: absolute;
    z-index: 12;
    top: auto;
    bottom: rem(91);

    @include until($breakpoint-small) {
      bottom: rem(13);
    }
  }

  &:hover {
    text-decoration: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    height: rem(14);
    width: rem(14);
    border-radius: rem(3);
    background-color: $color-other-label-green;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: top;
  }

  &--past {
    left: rem(237);

    &::before {
      left: rem(-6);
    }

    @include until($breakpoint-small) {
      padding: rem(5) rem(10);
    }
  }

  &--future {
    right: rem(10);

    &::before {
      right: rem(-6);
    }

    @include until($breakpoint-small) {
      padding: rem(5) rem(10);
    }
  }

  &--primetime {
    padding-right: rem(40);
    background-color: $color-other-label-purple;

    &:before {
      background-color: $color-other-label-purple;
    }

    &::after {
      content: '';
      background: url('~@/assets/images/svg/moon.svg') no-repeat;
      position: absolute;
      top: 50%;
      right: rem(13);
      width: rem(15);
      height: rem(16);
      transform: translateY(-50%);
    }

    @include until($breakpoint-small) {
      padding: rem(14) rem(40) rem(14) 0;
    }
  }

  &-fade-enter-active,
  &-fade-leave-active {
    transition: opacity 0.15s;
  }

  &-fade-enter,
  &-fade-leave-to {
    opacity: 0;
  }

  &--text {
    @include until($breakpoint-small) {
      display: none;
    }
  }
}
</style>
