<template>
  <div
    class="grid__time-strip time-strip"
    :class="{
      'time-strip--fixed': headerPosition === 'fixed',
      'time-strip--sticked': headerPosition === 'sticky',
    }"
  >
    <div
      class="time-strip__inner"
      :style="{
        width: `${width}px`,
        transform: `translate3d(${headerPosition === 'fixed' ? -gridTranslate : 0}px, 0px, 0px)`,
      }"
    >
      <div class="time-strip__column" v-for="hour in 24 * daysNumber" :key="hour">
        {{ `${formatHour(hour)}:00` }}
      </div>
      <div class="grid__time-pointer time-pointer" :style="{ width: `${pointerPosition}px` }"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {MINUTE_SIZE_IN_PIXELS} from '@/config';

export default {
  name: 'GridTimeStrip',

  props: {
    gridTranslate: Number,
    headerPosition: String,
  },

  computed: {
    ...mapGetters(['daysNumber', 'initialTimestamp', 'currentTimestamp']),
    width() {
      return this.daysNumber * 24 * 60 * MINUTE_SIZE_IN_PIXELS;
    },
    pointerPosition() {
      return (this.currentTimestamp - this.initialTimestamp) * MINUTE_SIZE_IN_PIXELS;
    },
  },

  methods: {
    formatHour(hour) {
      const formatedHour = hour - 1 - 24 * Math.floor((hour - 1) / 24);
      return formatedHour < 10 ? `0${formatedHour}` : formatedHour;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.time-strip {
  position: absolute;
  font-size: rem(12);
  border-bottom: rem(2) solid $color-border-primary;
  transform: translate3d(0, 0, 0);

  &--fixed {
    position: fixed;
    top: rem(56);
    z-index: 9;

    @include until($breakpoint-small) {
      top: rem(133);
    }
  }

  &--sticked {
    position: absolute;
    bottom: rem(93);
    z-index: 9;

    @include until($breakpoint-small) {
      bottom: rem(26);
    }
  }

  &__inner {
    display: flex;
    height: rem(33);
    will-change: transform;
  }

  &__column {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0 rem(10);
    background-color: $color-background-primary;
    border-right: rem(2) solid $color-border-primary;

    &:last-child {
      border-color: transparent;
    }
  }
}

.time-pointer {
  position: absolute;
  height: rem(35);
  top: 0;
  left: 0;
  background-color: rgba($color-other-progress, 0.1);
  border-right: rem(2) solid $color-other-progress;
  pointer-events: none;
  z-index: 5;
}
</style>
