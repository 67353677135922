<template>
  <div class="grid__channel-list channel-list">
    <div class="channel-list__channel" v-for="{ id, attributes } in channels" :key="id">
      <span class="channel-list__channel-logo">
        <img :src="attributes.logoUrl" :alt="attributes.name" :title="attributes.name" />
      </span>
      <span class="channel-list__channel-title">{{ attributes.name }}</span>
      <span
        class="channel-list__channel-icon"
        :class="{ 'channel-list__channel-icon--favorited': isFavorited(id) }"
        @click="() => clickHandler(id, attributes.name)"
        ><svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 24 24">
          <path
            d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_FAVORITE_CHANNEL } from '@/store/actions/guide';
import { analyticsData } from '@/utils';

export default {
  name: 'GridChannelList',

  computed: {
    ...mapGetters(['channelsList', 'channels', 'favoriteChannels']),
  },

  methods: {
    clickHandler(channelId, channelName) {
      this.$store.dispatch(SET_FAVORITE_CHANNEL, channelId);

      /* GTM | Web & Apps */
      if (this.isFavorited(channelId)) {
        this.gtmAddFavoriteEvent(channelName);
      }
    },

    isFavorited(id) {
      return this.favoriteChannels.indexOf(id) !== -1;
    },

    gtmAddFavoriteEvent(channelName) {
      analyticsData.send({
        event: 'tvProgram_timeline_addFavorite',
        item: { category: channelName },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts.scss';

.channel-list {
  width: rem(227);
  padding-top: rem(33);
  z-index: 9;
  background-color: $color-background-light;
  border-right: rem(2) solid $color-border-primary;

  &__channel {
    position: relative;
    display: flex;
    align-items: center;
    height: rem(73);
    padding-left: rem(30);
    background-color: $color-background-primary;
    border-top: rem(2) solid $color-border-primary;
    text-transform: uppercase;
  }

  &__channel-logo {
    max-width: rem(36);
  }

  &__channel-title {
    width: rem(120);
    padding-left: rem(20);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__channel-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(30);
    width: rem(30);
    right: rem(10);
    line-height: 0;
    cursor: pointer;

    svg {
      fill: transparent;
      stroke: $color-other-heart;
      stroke-width: 2px;
      position: relative;
    }

    &--favorited {
      svg {
        fill: $color-other-heart;
      }
    }
  }
}
</style>
