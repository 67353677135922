var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"time-label-fade"}},[(_vm.userPosition.inPast || _vm.userPosition.inFuture)?_c('div',{staticClass:"grid__time-label time-label",class:{
        'time-label--past': _vm.userPosition.inFuture,
        'time-label--future': _vm.userPosition.inPast,
        'time-label--fixed': _vm.headerPosition === 'fixed',
        'time-label--sticked': _vm.headerPosition === 'sticky',
      },on:{"click":_vm.translateGridToCurrent}},[_c('span',{staticClass:"time-label--text"},[_vm._v("Zpět na ")]),_vm._v(_vm._s(_vm.currentTime)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"time-label-fade"}},[(_vm.showPrimetimeLabel)?_c('div',{staticClass:"grid__time-label time-label time-label--primetime",class:{
        'time-label--past': _vm.userPosition.primetime === 'inPast',
        'time-label--future': _vm.userPosition.primetime === 'inFuture',
        'time-label--fixed': _vm.headerPosition === 'fixed',
        'time-label--sticked': _vm.headerPosition === 'sticky',
      },on:{"click":_vm.translateGridToPrimetime}},[_c('span',{staticClass:"time-label--text"},[_vm._v("Dnes večer")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }