import { SEARCH_STATIONS, PROGRAMS_SEARCH } from '@/api/constants';
import { makeRequest } from '@/utils';

/**
 * @typedef {Object} ChannelSearch
 * @property {string} id
 * @property {string} type
 * @property {Object} attributes
 * @property {string} attributes.name
 * @property {string} attributes.logoUrl
 * @property {number} attributes.language
 *
 */

/**
 * @typedef {Object} ProgramSearch
 * @property {string} id
 * @property {string} type
 * @property {Object} attributes
 * @property {string} attributes.name
 * @property {number} attributes.station
 * @property {string} attributes.startDateTime
 * @property {number|null} attributes.episodeNumber
 * @property {string} attributes.contentType
 *
 */

export default {
  /**
   * @description Returns a list of all stations by search string.
   * @param {string} query
   * @return {Promise<ChannelSearch[]>}
   */
  getChannels: async (query) => {
    try {
      const response = await makeRequest.get(`${SEARCH_STATIONS}/${query}`);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {
        status: 'error',
      };
    }
  },

  /**
   * @description Returns a list of all programs by search string.
   * @param {String} query
   * @return {Promise<ProgramSearch[]>}
   */
  getPrograms: async (query) => {
    try {
      const response = await makeRequest.get(`${PROGRAMS_SEARCH}/${query}`);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {
        status: 'error',
      };
    }
  },
};
