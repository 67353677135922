import store from '@/store';
import {dragger} from '@/utils';
import {TOGGLE_PROGRAM_DETAIL} from '@/store/actions/program-detail';

/* SLIDER */
export const createSlider = (element, stepWidth) => {
  const sliderInner = element.querySelector('.horizontal-strip__content--js');
  const sliderItem = element.querySelector('.slider-item--js');
  const sliderItems = Array.from(element.querySelectorAll('.slider-item--js'));
  const sliderItemOffsetWidth = stepWidth ? stepWidth : sliderItem.offsetWidth;
  const sliderItemMarginRight = parseInt(window.getComputedStyle(sliderItem).marginRight, 10);
  const sliderItemWidth = sliderItemOffsetWidth + sliderItemMarginRight;
  const contentMarginLeft = parseInt(window.getComputedStyle(sliderInner).marginLeft, 10);
  const slideNextButton = element.querySelector('.slider-next');
  const slidePreviousButton = element.querySelector('.slider-previous');

  let translate = 0;
  let originalTranslate;

  const toggleStepButtons = () => {
    if (slidePreviousButton && slideNextButton) {
      /* previous button */
      if (translate === 0 || getMaxTranslate() <= 0) {
        slidePreviousButton.style.display = 'none';
      } else {
        slidePreviousButton.style.display = '';
      }

      /* next button */
      if (translate === getMaxTranslate() || getMaxTranslate() <= 0) {
        slideNextButton.style.display = 'none';
      } else {
        slideNextButton.style.display = '';
      }
    }
  };

  const doTranslate = (position, fromStepButtonClick = false) => {
    translate = Math.min(getMaxTranslate(), Math.max(0, position));

    if (fromStepButtonClick) {
      element.scroll({
        left: translate,
        behavior: 'smooth',
      });
    } else {
      element.scrollLeft = translate;
    }

    toggleStepButtons();
  };

  const dragCallbackCurrent = (distance) => {
    doTranslate(translate + distance);
  };

  const clickHandler = (e) => {
    if (originalTranslate === translate) {
      if (e.target.classList.contains('open-detail--js')) {
        let programId = Number(e.target.getAttribute('data-id'));

        store.dispatch(TOGGLE_PROGRAM_DETAIL, programId);
      }
    } else {
      e.preventDefault();
    }
  };

  const getMaxTranslate = () => {
    return sliderInner.clientWidth - element.clientWidth + contentMarginLeft;
  };

  element.addEventListener('mousedown', (e) => {
    originalTranslate = translate;
    dragger.mouseDrag(e, dragCallbackCurrent);
  });

  element.addEventListener('scroll', () => {
    const wrapperLeft = element.scrollLeft;
    translate = Math.min(getMaxTranslate(), Math.max(0, wrapperLeft));

    toggleStepButtons();
  });

  /* Listener - open program detail/ prevent from open during mousedrag */
  sliderItems.forEach(function(target) {
    target.addEventListener('click', clickHandler);
  });

  /* Hide-show next/ previous button */
  toggleStepButtons();

  /* Next item */
  if (slideNextButton) {
    slideNextButton.addEventListener('click', () => {
      doTranslate(translate + sliderItemWidth, true);
      toggleStepButtons();
    });
  }

  /* Previous item */
  if (slidePreviousButton) {
    slidePreviousButton.addEventListener('click', () => {
      doTranslate(translate - sliderItemWidth, true);
      toggleStepButtons();
    });
  }
};
