var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid__program-list program-list"},_vm._l((_vm.channels),function(ref,rowIndex){
var id = ref.id;
return _c('div',{key:id,ref:"programRow",refInFor:true,staticClass:"program-list__program-row",attrs:{"id":("program-row-" + id)}},[_c('div',{staticClass:"program-list__inner"},_vm._l((_vm.selectedPrograms[id]),function(ref,i){
var id = ref.id;
var attributes = ref.attributes;
return _c('div',{key:id + i,staticClass:"program-list__program",class:{ 'program-list__program--unavailable': id === 'unavailable' },style:({
          width: attributes.width,
          left: attributes.left,
        }),attrs:{"title":attributes.name},on:{"click":function (event) { return _vm.onClick(event, id, attributes, rowIndex); },"mousedown":_vm.onMouseDown}},[_c('div',{staticClass:"program-list__program-title"},[_vm._v(" "+_vm._s(attributes.name)+" ")]),(id !== 'unavailable')?_c('div',{staticClass:"program-list__program-meta"},[_c('span',{staticClass:"program-list__program-time"},[_vm._v(_vm._s(_vm.formatTime(attributes.minutes)))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.formatTime(attributes.minutes + attributes.lengthMinutes)))]),_c('span',{staticClass:"program-list__program-duration"},[_vm._v(_vm._s(_vm.getDuration(attributes.lengthMinutes)))])]):_vm._e(),(parseInt(attributes.width, 10) < 140)?_c('div',{staticClass:"program-list__tooltip"},[_c('div',{staticClass:"program-list__tooltip-title"},[_vm._v(" "+_vm._s(attributes.name)+" ")]),(id !== 'unavailable')?_c('div',{staticClass:"program-list__tooltip-meta"},[_c('span',{staticClass:"program-list__tooltip-time"},[_vm._v(_vm._s(_vm.formatTime(attributes.minutes)))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.formatTime(attributes.minutes + attributes.lengthMinutes)))]),_c('span',{staticClass:"program-list__tooltip-duration"},[_vm._v(_vm._s(_vm.getDuration(attributes.lengthMinutes)))])]):_vm._e()]):_vm._e()])}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }