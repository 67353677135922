<template>
  <div class="guide__search search">
    <form @submit.prevent="onSubmit" class="search__form">
      <input
        v-model="search"
        type="text"
        placeholder="Název stanice nebo pořadu..."
        ref="searchInput"
      />
      <span v-show="search" @click="clearSearch" class="search__remove" ></span>
    </form>
    <SearchWhisperer :query="search"  @onChannelsClick="onChannelsClick" />
  </div>
</template>

<script>
import SearchWhisperer from '@/components/Search/SearchWhisperer';
import {SET_CHANNELS_FILTER} from "@/store/actions/guide";
import {storage} from "@/storage";
import {FITLER_DEFAULT} from "@/config";

export default {
  name: 'GuideSearch',

  components: { SearchWhisperer },

  data() {
    return {
      search: '',
      isResult: Boolean,
    };
  },

  methods: {
    onSubmit() {
      window.location = `/hledani?search=${this.search}`;
    },

    getSearchInput() {
      return this.$refs.searchInput;
    },

    clearSearch() {
      this.search = '';

      if (this.isResult) {
        this.$store.dispatch(SET_CHANNELS_FILTER, storage.get()['channelsFilter'] ?? FITLER_DEFAULT);
      }
    },

    onChannelsClick(value) {
      this.isResult = value;
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

@mixin background-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18.25 18.25'%3E%3Cpath fill='%231f2024' d='M17.729 14.948l-4.068-4.068a7.3 7.3 0 1 0-2.781 2.781l4.067 4.067a1.68 1.68 0 0 0 2.433 0l.348-.348a1.68 1.68 0 0 0 .001-2.432zM1.39 7.3a5.91 5.91 0 1 1 5.91 5.91A5.91 5.91 0 0 1 1.39 7.3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.search {
  position: relative;

  &__form {
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    input {
      @include background-icon;
      width: 100%;
      padding: rem(11) rem(40) rem(11) rem(15);
      font-size: rem(15);
      line-height: 1.2;
      border: rem(2) solid $color-border-primary;
      border-radius: rem(6);
      background-position: calc(100% - 10px) center;
      transition: width 0.3s;
      position: relative;

      &:hover {
        border-color: $color-border-hover;
      }

      &:focus {
        border-color: $color-highlight-primary;
        box-shadow: none;
        outline: 0 none;
      }

      @include until($breakpoint-small) {
        width: 100%;
        font-size: rem(16);
      }
    }
  }

  &__trigger {
    @include background-icon;
    position: absolute;
    display: none;
    top: rem(13);
    right: rem(10);
    width: rem(20);
    height: rem(20);
    cursor: pointer;

    @include until($breakpoint-small) {
      display: block;
    }
  }

  &__remove {
    cursor: pointer;
    top: rem(7);
    right: rem(5);
    width: rem(30);
    height: rem(30);
    position: absolute;
    display: block;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAjlJREFUaEPtmb8uRUEQhz+NSIRHoCY0qAihI9EJDZ6ChgegEQ/hX4cQD4BER4eeV6BSySSO3By7e3Z2Z+Pe5N7mFHdn9vftnJnd2dNDh/96Olw/XYD/jmA3Au0egQ1gFxgCnoAt4LGw6CngEJgA3oE94MQ3Z+gV2gSOaoZfwBpwVQhiCbgA+mr+ZSFPXXOGAF6BEYeRQKwAN8YQy8A50Ovw+wKMaQE+gX6PSGuIkHiRIFoGtAB3wFxgla0gmsSLhFtgQQsgyfTgCWnlKxciRrzMMf1TRP4wNO0Di8ClI6laHaUmti9h675XgeuUKlTZlIAwES8CmyJQAsJMvAZAxlpEwlS8FiAXwlx8CkAqRBHxqQBaCKlSruOBqtrkVCGfbWwNF3vX8cBkL4mtQj6ImMQOHZkkOsE633TeygWIfZ1cOrLF5+RAXZA2EibiLQE0kTATbw0QUyplztSzkzMdLHJAHMeKb608Jp2dBYBWvClELkCqeDOIHICO3shiVr6qNvIs1RRF9wOtFUAjvuqkYvaJpOqkfYVSxJdoin4XVAOQI74YRCyAhfgiEDEAluLNIZoASog3hQgBtNPF1ozvVjwEcA/MBhqK3Fu5ynXMhijXnPMuLSGAdrrc/QAGtQBypT3qMLJa+brrUCSegXEtgHxUOK4ZmTYjDkG+HXsdONMCyHgx3AGGf5Jo23dL3NR8K/6X4nEATAJvwL7v64x1R6bQaDe0aR+wm6mQpy5AoYWNdtuNQPRSFRr4DRzDtDELHD+vAAAAAElFTkSuQmCC");
    background-size: rem(20) rem(20);
  }
}
</style>
