<template>
  <div
    class="guide__grid grid"
    ref="grid"
    :class="{ 'grid--header-sticked': headerPosition !== 'none' }"
  >
    <GuideChannelsFilter :headerPosition="headerPosition" />
    <div
      class="grid__loading-status"
      v-if="isRendering"
      :class="{
        'grid__loading-status--fixed': headerPosition === 'fixed',
        'grid__loading-status--sticked': headerPosition === 'sticky',
      }"
    >
      <span class="status__icon icon icon--spinner-dark icon--40x40"></span>
    </div>
    <GridDateStrip
      :gridTranslate="translate"
      :doGridTranslate="doTranslate"
      :headerPosition="headerPosition"
      :translateGridToCurrent="translateToCurrent"
      ref="dateStrip"
    />
    <GridTimeLabels
      :translateGridToCurrent="translateToCurrent"
      :translateGridToPrimetime="translateToPrimetime"
      :headerPosition="headerPosition"
    />

    <div class="grid__timeline" @mousemove="onGridMouseMove" @mouseleave="onGridMouseLeave">
      <GridChannelList ref="gridChannelList" />
      <NavigationArrow
        :visibility="isArrowNavigationVisible"
        :position="arrowNavigationPosition"
        :doGridTranslate="doTranslate"
        :translate="translate"
      />

      <div class="grid__channel-programs" ref="gridChannelPrograms" @scroll="onGridScroll">
        <GridTimeStrip
          :gridTranslate="translate"
          :headerPosition="headerPosition"
          ref="timeStrip"
        />
        <div
          class="grid__channel-programs-inner"
          @mousedown="onMouseStart"
          :style="{
            width: `${innerWidth}px`,
          }"
        >
          <GridProgramList />
          <GridTimePointer />
        </div>
      </div>
    </div>
    <GridNoneFavorite />
  </div>
</template>

<script>
import GuideChannelsFilter from "@/components/Guide/GuideChannelsFilter";
import GridDateStrip from '@/components/Guide/Grid/GridDateStrip';
import GridTimeLabels from '@/components/Guide/Grid/GridTimeLabels';
import GridChannelList from '@/components/Guide/Grid/GridChannelList';
import GridProgramList from '@/components/Guide/Grid/GridProgramList';
import GridTimeStrip from '@/components/Guide/Grid/GridTimeStrip';
import GridTimePointer from '@/components/Guide/Grid/GridTimePointer';
import GridNoneFavorite from '@/components/Guide/Grid/GridNoneFavorite';
import NavigationArrow from '@/components/Guide/Grid/NavigationArrow';
import { mapGetters } from 'vuex';
import { SET_CURRENT_TIMESTAMP, SET_USER_POSITION } from '@/store/actions/guide';
import { BREAKPOINT_MOBILE, INITIAL_POINTER_OFFSET, MINUTE_SIZE_IN_PIXELS } from '@/config';
import { analyticsData, dragger, getDate } from '@/utils';

const GRID_BORDER_HEIGHT = 2;
const DATE_STRIP_HEIGHT = 56;
const TIME_STRIP_HEIGHT = 35;
const CHANNELS_FILTER_HEIGHT = 100;
const MORNING_TIME_OFFSET = 420; // 7am
const EVENING_TIME_OFFSET = 1800; // next day 6am

export default {
  name: 'Grid',

  components: {
    GuideChannelsFilter,
    GridDateStrip,
    GridTimeLabels,
    GridChannelList,
    GridProgramList,
    GridTimeStrip,
    GridTimePointer,
    GridNoneFavorite,
    NavigationArrow,
  },

  data() {
    return {
      translate: 0,
      headerPosition: 'none',
      initialDateTime: '',
      lastVisitedDay: '',
      channelListsWidth: null,
      isArrowNavigationVisible: { left: false, right: false },
      arrowNavigationPosition: { position: 'absolute', translate: 0 },
    };
  },

  computed: {
    ...mapGetters([
      'initialTimestamp',
      'currentTimestamp',
      'daysNumber',
      'userPosition',
      'isRendering',
    ]),
    innerWidth() {
      return this.daysNumber * 24 * 60 * MINUTE_SIZE_IN_PIXELS;
    },
  },

  watch: {
    'userPosition.breakpoint'() {
      if (!this.initialDateTime) {
        this.initialDateTime = new Date(this.userPosition.dayInMinutes * 60 * 1000);
      }

      /* Today */
      const currentDay = this.initialDateTime.getDate();
      const currentMonth = this.initialDateTime.getMonth() + 1;
      const currentYear = this.initialDateTime.getFullYear();

      /* User selected/ scrolled date */
      const selectedDateTime = new Date(this.userPosition.dayInMinutes * 60 * 1000);
      const selectedDay = selectedDateTime.getDate();
      const selectedMonth = selectedDateTime.getMonth() + 1;
      const selectedYear = selectedDateTime.getFullYear();

      /* Set selected day by user */
      if (!this.lastVisitedDay) this.lastVisitedDay = selectedDay;

      const currentDate = new Date(`${currentMonth}/${currentDay}/${currentYear}`);
      const selectedDate = new Date(`${selectedMonth}/${selectedDay}/${selectedYear}`);
      const timeDifference = Math.abs(currentDate - selectedDate);
      let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      /* Check for user's position (past or future) */
      if (this.initialDateTime > selectedDateTime && dayDifference > 0) {
        dayDifference = dayDifference * -1;
      }

      /* Send GA */
      if (this.lastVisitedDay !== selectedDay) {
        this.gtmDayChangeEvent(dayDifference);

        /* Set new visited day */
        this.lastVisitedDay = selectedDay;
      }
    },
  },

  mounted() {
    setInterval(this.setCurrentTimestamp, 1000);
    this.translateToCurrent();
    this.setHeaderPosition();
    this.setChannelListWidth();
    window.addEventListener('resize', this.onWindowResize);
    document.addEventListener('scroll', this.onDocumentScroll);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('scroll', this.onDocumentScroll);
  },

  updated() {
    this.setShowArticlesAndFooter();
  },

  methods: {
    setCurrentTimestamp() {
      const currentTimestamp = getDate.toMinutes();
      if (this.currentTimestamp !== currentTimestamp) {
        this.$store.dispatch(SET_CURRENT_TIMESTAMP, currentTimestamp);
      }
    },

    setChannelListWidth() {
      this.channelListsWidth = this.$refs.gridChannelList.$el.offsetWidth;
    },

    getChannelPrograms() {
      return this.$refs.gridChannelPrograms;
    },

    getMaxTranslate() {
      return this.innerWidth - this.getChannelPrograms().clientWidth;
    },

    doTranslate(position) {
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, position));
      this.getChannelPrograms().scrollLeft = this.translate;
    },

    translateToCurrent() {
      const currentMinutes = getDate.toMinutes() - this.initialTimestamp;
      const pointerOffset =
        window.innerWidth < BREAKPOINT_MOBILE ? INITIAL_POINTER_OFFSET / 2 : INITIAL_POINTER_OFFSET;
      this.doTranslate(currentMinutes * MINUTE_SIZE_IN_PIXELS - pointerOffset);
    },

    translateToPrimetime() {
      const primetime = getDate.getTodayMinutsByHour(20) - this.initialTimestamp;
      this.doTranslate(primetime * MINUTE_SIZE_IN_PIXELS);
    },

    setUserPosition() {
      const {
        initialTimestamp,
        currentTimestamp,
        userPosition,
        translate,
        getChannelPrograms,
        $store,
      } = this;
      const channelProgramsWidth = getChannelPrograms().clientWidth;
      const translateInMinutes = translate / MINUTE_SIZE_IN_PIXELS;
      const gridWidthInMinutes = channelProgramsWidth / MINUTE_SIZE_IN_PIXELS;

      const inPast = translateInMinutes + gridWidthInMinutes < currentTimestamp - initialTimestamp;
      const inFuture = translateInMinutes > currentTimestamp - initialTimestamp;

      // The beginning of the day
      const commonBreak1 = Math.floor(translateInMinutes / 1440);
      // Api call date changed - for example 7am
      const commonBreak2 = Math.floor((translateInMinutes - MORNING_TIME_OFFSET) / 1440);
      // End of the day
      const commonBreak3 = Math.floor(
        (translateInMinutes + gridWidthInMinutes - EVENING_TIME_OFFSET) / 1440
      );

      const primetimeInMinutes = getDate.getTodayMinutsByHour(20) - initialTimestamp;
      let primetime =
        primetimeInMinutes < translateInMinutes
          ? 'inPast'
          : primetimeInMinutes > translateInMinutes + gridWidthInMinutes
          ? 'inFuture'
          : null;

      const position = {
        inPast,
        inFuture,
        breakpoint: [commonBreak1, commonBreak2, commonBreak3],
        dayInMinutes: userPosition.dayInMinutes,
        dates: userPosition.dates,
        primetime,
      };

      if (JSON.stringify(userPosition) !== JSON.stringify(position)) {
        const dates = [];

        const fechDay1 = getDate.parsedDate(
          (translateInMinutes - MORNING_TIME_OFFSET + initialTimestamp) * 1000 * 60
        );

        const fechDay2 = getDate.parsedDate(
          (translateInMinutes + gridWidthInMinutes + initialTimestamp) * 1000 * 60
        );

        const formatedDay1 = this.getFormattedDay(fechDay1);
        const formatedDay2 = this.getFormattedDay(fechDay2);

        dates.push(formatedDay1);

        if (formatedDay1 !== formatedDay2) dates.push(formatedDay2);

        position.dates = dates;
        position.dayInMinutes = translateInMinutes + initialTimestamp;

        $store.dispatch(SET_USER_POSITION, position);
      }
    },

    getFormattedDay(day) {
      return `${day.year}-${day.month < 10 ? '0' + day.month : day.month}-${
        day.day < 10 ? '0' + day.day : day.day
      }`;
    },

    dragCallback(distance) {
      this.doTranslate(this.translate + distance);
    },

    onMouseStart(event) {
      dragger.mouseDrag(event, this.dragCallback);
    },

    setArrowNavigationPosition() {
      // On mobile, do nothing
      if (window.innerWidth < BREAKPOINT_MOBILE) return;

      const programListTop = this.getChannelPrograms().getBoundingClientRect().top;
      const programListHeight = this.getChannelPrograms().clientHeight;
      const programListBottom = programListTop + programListHeight;

      let arrowPosition;

      if (programListTop > 0) {
        // ProgramList: The top edge is visible, the bottom is not
        arrowPosition = {
          position: 'absolute',
          translate: -((programListBottom - window.innerHeight) / 2),
        };
      }

      if (programListTop > 0 && programListBottom < window.innerHeight) {
        // ProgramList: The top and bottom edges are visible
        arrowPosition = {
          position: 'absolute',
          translate: 0,
        };
      }

      if (programListTop < 0 && programListBottom > window.innerHeight) {
        // ProgramList: The top and bottom edges are not visible
        arrowPosition = {
          position: 'fixed',
          translate: 0,
        };
      }

      if (programListTop < 0 && programListBottom < window.innerHeight) {
        // ProgramList: The top edge is not visible, the bottom is visible
        arrowPosition = {
          position: 'absolute',
          translate: -programListTop / 2,
        };
      }

      this.arrowNavigationPosition = arrowPosition;
    },

    setArrowNavigationVisibility(mouseXposition) {
      const { innerWidth } = window;

      // On mobile, do nothing
      if (innerWidth < BREAKPOINT_MOBILE) return;

      this.setArrowNavigationPosition();

      // Hover active zone in px
      const hoverZone = (innerWidth - this.channelListsWidth) / 2;

      // The right edge of the program
      if (innerWidth - mouseXposition < hoverZone) {
        this.isArrowNavigationVisible = { left: false, right: true };
        return;
      }

      // The left edge of the program
      if (
        mouseXposition > this.channelListsWidth &&
        mouseXposition < this.channelListsWidth + hoverZone
      ) {
        this.isArrowNavigationVisible = { left: true, right: false };
        return;
      }

      this.isArrowNavigationVisible = { left: false, right: false };
    },

    onGridMouseMove({ clientX }) {
      this.setArrowNavigationVisibility(clientX);
    },

    onGridMouseLeave() {
      this.isArrowNavigationVisible = { left: false, right: false };
    },

    onGridScroll() {
      const channelProgramsLeft = this.getChannelPrograms().scrollLeft;
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, channelProgramsLeft));
      this.setUserPosition();
    },

    setHeaderPosition() {
      const { grid } = this.$refs;
      const gridTopPosition = grid.getBoundingClientRect().top;
      const gridStickyHeight = grid.clientHeight - DATE_STRIP_HEIGHT - TIME_STRIP_HEIGHT - CHANNELS_FILTER_HEIGHT;

      if (gridTopPosition <= -GRID_BORDER_HEIGHT) {
        if (gridTopPosition + gridStickyHeight <= -GRID_BORDER_HEIGHT * 2) {
          this.headerPosition = 'sticky';
          this.$emit('headerPosition', 'sticky');
          return;
        }
        this.headerPosition = 'fixed';
        this.$emit('headerPosition', 'fixed');
        return;
      }
      this.$emit('headerPosition', 'none');
      this.headerPosition = 'none';
    },

    setShowArticlesAndFooter() {
      const articles = document.getElementById('clanky');
      const footer = document.getElementById('footer-global').parentElement;

      if (articles.style.display === 'none') {
        articles.style.display = 'block';
      }

      if (footer.style.display === 'none') {
        footer.style.display = 'block';
      }
    },

    onWindowResize() {
      this.doTranslate(this.translate);
      this.setHeaderPosition();
      this.setChannelListWidth();
    },

    onDocumentScroll() {
      this.setHeaderPosition();
      this.setArrowNavigationPosition();
    },

    gtmDayChangeEvent(dayDifference) {
      analyticsData.send({
        event: 'tvProgram_timeline_selectDay',
        item: { position: dayDifference },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';
.grid {
  position: relative;
  font-size: rem(13);
  color: $color-text-dark;
  border-bottom: rem(2) solid $color-border-primary;

  &--header-sticked {
    padding-top: rem(56);
  }

  &__timeline {
    display: flex;
    overflow: hidden;
    position: relative;
  }

  /deep/ &__time-label {
    @include until($breakpoint-small) {
      &.time-label--past {
        left: rem(100);
      }
    }
  }

  /deep/ &__channel-list {
    @include until($breakpoint-small) {
      width: rem(90);

      .channel-list__channel {
        padding-left: rem(15);
      }

      .channel-list__channel-title {
        display: none;
      }

      .channel-list__channel-icon {
        right: rem(3);
      }
    }
  }

  &__channel-programs {
    position: relative;
    flex: 1;
    z-index: 8;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__channel-programs-inner {
    display: flex;
    flex-direction: column;
    padding-top: rem(33);
    will-change: transform;
  }

  &__loading-status {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(42);
    height: rem(42);
    top: rem(6);
    left: 50%;
    z-index: 12;
    background-color: rgba($color-border-primary, 0.8);
    border: rem(2) solid $color-border-primary;
    border-radius: rem(90);
    transform: translateX(-50%);

    &--fixed {
      position: fixed;
    }

    &--sticked {
      position: absolute;
    }
  }
}
</style>
