import {STATIONS} from '@/api/constants';
import {makeRequest} from '@/utils';

export default {
	/**
	 * @description Returns a list of all channels.
	 */
	getChannels: async () => {
		try {
			const response = await makeRequest.get(STATIONS);
			return response.data.data;
		} catch (error) {
			console.warn(error);
			return {};
		}
	},
};
