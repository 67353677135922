<template>
  <div>
    <div
      class="date-strip__navigation"
      :class="{
        'date-strip__navigation--fixed': headerPosition === 'fixed',
        'date-strip__navigation--sticked': headerPosition === 'sticky',
      }"
    >
      <div class="date-strip__navigation-left" @click="navigationArrowHandler('left')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </div>
      <div class="date-strip__navigation-right" @click="navigationArrowHandler('right')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </div>
    </div>

    <div
      ref="dateStrip"
      :class="{
      'date-strip--fixed': headerPosition === 'fixed',
      'date-strip--sticked': headerPosition === 'sticky',
    }"
      class="grid__date-strip date-strip"
      @scroll="onStripScroll"
    >
      <div class="date-strip__inner" ref="inner" @mousedown="onMouseStart">
        <div
          v-for="{ weekday, date, timestamp, timezoneOffset, isSelected } in dates"
          :key="date"
          :class="{ 'date-strip__column--selected': isSelected }"
          class="date-strip__column"
          @click="() => clickHandler(timestamp, timezoneOffset, date)"
        >
          <div class="date-strip__weekday">{{ weekday }}</div>
          <div class="date-strip__date">
            {{ new Date().getDate() === date ? 'DNES' : `${date}.` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dragger, getDate } from '@/utils';
import { mapGetters } from 'vuex';
import {
  DAYS_NUMBER_VISIBLE,
  DAYS_NUMBER_BACK,
  MINUTE_SIZE_IN_PIXELS,
  NAME_DAYS,
  BREAKPOINT_MOBILE,
} from '@/config';

export default {
  name: 'GridDateStrip',

  data() {
    return {
      translate: 0,
      dates: [],
      clientWidth: null,
      initialRender: true,
    };
  },

  props: {
    gridTranslate: Number,
    doGridTranslate: Function,
    headerPosition: String,
    translateGridToCurrent: Function,
  },

  computed: {
    ...mapGetters(['initialTimestamp', 'daysNumber', 'userPosition']),
  },

  watch: {
    'userPosition.breakpoint'() {
      const userTimestamp = this.userPosition.dayInMinutes;
      this.dates = this.dates.map((date) => {
        const dateTimestamp = this.calcDateTimestamp(date.timestamp, date.timezoneOffset);
        date.isSelected = userTimestamp >= dateTimestamp && dateTimestamp > userTimestamp - 24 * 60;
        return date;
      });
    },

    dates() {
      this.setCurrentDayVisible();
    },
  },

  mounted() {
    this.clientWidth = document.documentElement.clientWidth;

    const { day, month, year } = getDate.parsedDate();
    const today = new Date(`${year}/${month}/${day}`);

    this.dates = new Array(this.daysNumber).fill('').map((date, index) => {
      const selectedDay = new Date(today).setDate(today.getDate() + index - DAYS_NUMBER_BACK);
      const selectedDate = new Date(selectedDay);
      return {
        weekday: NAME_DAYS[selectedDate.getDay()],
        date: selectedDate.getDate(),
        timestamp: selectedDay / 1000 / 60,
        timezoneOffset: selectedDate.getTimezoneOffset(),
        isSelected: false,
      };
    });

    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth;
      this.setCurrentDayVisible();
    });
  },

  methods: {
    calcDateTimestamp(timestamp, timezoneOffset) {
      const offsetDifference = new Date().getTimezoneOffset() - timezoneOffset;
      return timestamp + offsetDifference;
    },

    clickHandler(timestamp, timezoneOffset, date) {
      if (this.originalTranslate && this.originalTranslate !== this.translate) return false;

      if (new Date().getDate() === date) {
        this.translateGridToCurrent();
      } else {
        const dateTimestamp = this.calcDateTimestamp(timestamp, timezoneOffset);
        this.doGridTranslate((dateTimestamp - this.initialTimestamp) * MINUTE_SIZE_IN_PIXELS);
      }
    },

    getDateStrip() {
      return this.$refs.dateStrip;
    },

    getInner() {
      return this.$refs.inner;
    },

    getMaxTranslate() {
      return this.getInner().clientWidth - this.getDateStrip().clientWidth;
    },

    doTranslate(position) {
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, position));
      this.getDateStrip().scrollLeft = this.translate;
    },

    dragCallback(distance) {
      this.doTranslate(this.translate + distance);
    },

    onMouseStart(event) {
      this.originalTranslate = this.translate;
      dragger.mouseDrag(event, this.dragCallback);
    },

    onStripScroll() {
      const dateStripLeft = this.getDateStrip().scrollLeft;
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, dateStripLeft));
    },

    getNumberVisibleDays() {
      return this.clientWidth < BREAKPOINT_MOBILE
        ? DAYS_NUMBER_VISIBLE.mobile
        : DAYS_NUMBER_VISIBLE.desktop;
    },

    getScrollPositionCurrentDay() {
      const visibleDays = this.getNumberVisibleDays();
      return (this.clientWidth / visibleDays) * (DAYS_NUMBER_BACK - 1);
    },

    navigationArrowHandler(direction) {
      const dayWidth = this.clientWidth / this.getNumberVisibleDays();
      const roudedCurrentDays = Math.ceil(this.getDateStrip().scrollLeft / dayWidth);

      const scrollLeft =
        direction === 'left'
          ? roudedCurrentDays * dayWidth - dayWidth
          : roudedCurrentDays * dayWidth + dayWidth;

      this.getDateStrip().scrollLeft = scrollLeft;
    },

    setCurrentDayVisible() {
      const selectedDate = this.dates.find((date) => date.isSelected);
      const selectedDayIndex = this.dates.indexOf(selectedDate);
      const dayWidth = this.clientWidth / this.getNumberVisibleDays();
      const firstVisibleDayIndex = Math.ceil(this.getDateStrip().scrollLeft / dayWidth);
      const lastVisibleDayIndex = firstVisibleDayIndex + this.getNumberVisibleDays() - 1;

      // When the day index is less than the first visible
      if (selectedDayIndex < firstVisibleDayIndex) {
        this.getDateStrip().scrollLeft = selectedDayIndex * dayWidth;
      }

      // When the day index is larger than the last visible
      if (selectedDayIndex > lastVisibleDayIndex) {
        this.getDateStrip().scrollLeft =
          (selectedDayIndex - this.getNumberVisibleDays() + 1) * dayWidth;
      }

      // Scroll to init position
      if (this.initialRender) {
        this.getDateStrip().scrollLeft = this.getScrollPositionCurrentDay();
        this.initialRender = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.date-strip {
  background-color: $color-background-primary;
  transform: translate3d(0, 0, 0);
  overflow: auto hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    @include until($breakpoint-small) {
      top: rem(75);
    }
  }

  &--sticked {
    position: absolute;
    bottom: rem(128);
    left: 0;
    z-index: 10;
    width: 100%;

    @include until($breakpoint-small) {
      bottom: rem(61);
    }
  }

  &__inner {
    display: flex;
    min-width: 100% / 11 * 21;
    justify-content: space-evenly;
    border-bottom: rem(2) solid $color-border-primary;
    will-change: transform;

    @include until($breakpoint-small) {
      min-width: 100% / 8 * 21;
    }
  }

  &__column {
    flex: 1;
    position: relative;
    padding: rem(8) 0;
    text-align: center;
    cursor: pointer;
  }

  &__weekday {
    font-size: rem(11);
    color: darken($color-background-primary, 30%);
  }

  &__date {
    font-size: rem(15);
  }

  &__column--selected {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: rem(5);
      bottom: rem(-2);
      background-color: #d32e3b;
    }
  }

  &__navigation {
    position: absolute;
    top: rem(118);
    width: 100%;
    z-index: 99;
    will-change: transform;

    @include until($breakpoint-small) {
      top: rem(75);
    }

    @include from($breakpoint-header) {
      top: 0;
    }

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 12;
      width: 100%;

      @include until($breakpoint-small) {
        top: rem(75);
      }
    }

    &--sticked {
      position: absolute;
      bottom: rem(35);
      left: 0;
      z-index: 12;
      width: 100%;
    }
  }

  &__navigation-left,
  &__navigation-right {
    position: absolute;
    display: flex;
    align-items: center;
    height: rem(52);
    width: rem(60);
    background: rgba($color-background-primary, 0.7);
    cursor: pointer;

    &:hover {
      svg {
        stroke: $color-text-dark;
      }
    }

    svg {
      width: rem(24);
      height: rem(24);
      fill: none;
      stroke: $color-text-grey;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &__navigation-left {
    justify-content: flex-start;
    left: 0;
    background: linear-gradient(
      90deg,
      $color-background-primary,
      rgba($color-background-primary, 0)
    );
  }

  &__navigation-right {
    justify-content: flex-end;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba($color-background-primary, 0),
      $color-background-primary
    );
  }

  @include until($breakpoint-small) {
    border-top: rem(2) solid $color-border-primary;
  }
}
</style>
