<template>
  <div class="program-articles">
    <h2 class="program-articles__title">
      O tomto pořadu jsme napsali
    </h2>
    <div ref="listWrapper" class="program-articles__list-wrapper" @scroll="onScroll">
      <ul ref="articlesList" class="program-articles__list">
        <li v-for="(article, index) in programArticles.slice(0, this.visibleResults)" :key="index"
            class="program-articles__item">
          <article class="program-articles__article">
            <a :href="article.attributes.articleUrl" class="program-articles__article-image-link" target="_blank">
              <img :alt="article.attributes.title"
                   :src="article.attributes.imageUrl"
                   class="program-articles__article-image"
                   loading="lazy"
              >
            </a>
            <h3>
              <a :href="article.attributes.articleUrl" class="program-articles__article-title" target="_blank">
                {{ article.attributes.title }}
              </a>
            </h3>
          </article>
        </li>
      </ul>
    </div>

    <!-- load more -->
    <button v-if="programArticles.length > visibleResults" class="button" @click="showMoreContent">
      Další články o pořadu
    </button>
  </div>
</template>

<script>
import {INITIAL_NUMBER_PROGRAM_ARTICLES, LOAD_MORE_NUMBER_PROGRAM_ARTICLES} from '@/config';

export default {
  name: 'ProgramDetailArticles',

  props: {
    programArticles: Array,
  },

  data() {
    return {
      visibleResults: INITIAL_NUMBER_PROGRAM_ARTICLES,
    }
  },

  methods: {
    showMoreContent() {
      this.visibleResults += LOAD_MORE_NUMBER_PROGRAM_ARTICLES;
    },

    getListWrapper() {
      return this.$refs.listWrapper;
    },

    getArticlesList() {
      return this.$refs.articlesList;
    },

    maxScroll() {
      return this.getArticlesList().offsetWidth - this.getListWrapper().offsetWidth;
    },

    onScroll() {
      const listLeft = this.getListWrapper().scrollLeft;

      if (listLeft >= this.maxScroll()) this.showMoreContent();
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.program-articles {
  &__title {
    margin-bottom: rem(20);
    font-size: rem(15);

    @include until($breakpoint-small) {
      margin-bottom: rem(10);
      font-size: rem(14);
    }
  }

  &__list-wrapper {
    @include until($breakpoint-small) {
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox hide scroll bar */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include until($breakpoint-small) {
      display: inline-flex;
      flex-wrap: nowrap;
    }
  }

  &__item {
    flex-basis: calc(33.3334% - 13.33333px);
    margin: 0 rem(20) rem(30) 0;

    @include until($breakpoint-small) {
      flex-basis: auto;
      width: rem(130);
    }

    &:nth-child(3n) {
      margin-right: 0;

      @include until($breakpoint-small) {
        margin-right: rem(20);
      }
    }

    &:last-child {
      @include until($breakpoint-small) {
        margin-right: 0;
      }
    }
  }

  &__article-image-link {
    display: block;
    width: 100%;
    height: rem(146);
    background-color: $color-background-primary; // placeholder

    @include until($breakpoint-small) {
      height: rem(78);
    }
  }

  &__article-image {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    margin-bottom: rem(15);

    @include until($breakpoint-small) {
      margin-bottom: rem(10);
    }
  }

  &__article-title {
    display: block;
    line-height: 1.4;
    font-size: rem(15);
    color: $color-text-light;
    text-decoration: none;

    @include until($breakpoint-small) {
      line-height: 1.2;
      font-size: rem(13);
    }
  }
}

.button {
  display: block;
  margin: 0 auto;

  @include until($breakpoint-small) {
    display: none;
  }
}
</style>
