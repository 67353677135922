<template>
  <div class="guide__load-more load-more">
    <button v-if="isVisible" class="button" @click="loadMore(nextLoadProgramsNumber)">Načíst další</button>
    <button v-if="isVisible" class="button button--minimalize" @click="loadAll">Načíst vše</button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_CHANNELS} from '@/store/actions/guide';
import {INITIAL_PROGRAMS_NUMBER, NEXT_LOAD_PROGRAMS_NUMBER} from '@/config';

export default {
  name: 'GridLoadMore',

  data() {
    return {
      nextLoadProgramsNumber: INITIAL_PROGRAMS_NUMBER + NEXT_LOAD_PROGRAMS_NUMBER,
    }
  },

  computed: {
    ...mapGetters(['channels', 'channelsList', 'channelsFilter']),
    isVisible() {
      return this.channels.length !== this.channelsList.length && this.channelsFilter === '1000';
    },
  },

  methods: {
    loadAll() {
      this.$store.dispatch(SET_CHANNELS, this.channelsList);
    },

    loadMore(channelsNumber) {
      this.$store.dispatch(SET_CHANNELS, this.channelsList.slice(0, channelsNumber));
      this.nextLoadProgramsNumber += NEXT_LOAD_PROGRAMS_NUMBER;
    },
  },
};
</script>

<style lang="scss">
@import 'src/styles/abstracts/abstracts';

.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(19);

  .button--minimalize {
    margin-left: rem(40);
  }
}
</style>
