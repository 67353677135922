import { STORAGE_KEY } from '@/config';

export const storage = {
  /**
   * @returns {Object}
   */
  get: () => {
    const state = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (state === null) return {};
    return state;
  },

  /**
   * @param {Object} data
   */
  set: (data) => {
    const state = JSON.parse(localStorage.getItem(STORAGE_KEY));
    const newState = { ...state, ...data };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newState));
  },
};
