<template>
  <transition name="toggle-gallery">
    <div class="gallery-detail" id="gallery-detail" v-if="isGalleryOpen">
      <div class="gallery-detail__content">
        <!-- Gallery image -->
        <img :src="galleryData.targetSrc"
             :alt="galleryData.targetAlt"
             :title="galleryData.targetTitle"
             class="gallery-detail__image"
        >

        <!-- Next/ previous icons -->
        <span class="gallery-detail__previous icon icon--previous"
              v-if="this.currentImg !== 1"
              @click="slidePreviousHandler"
              ref="galleryPreviousButton"
        ></span>

        <span class="gallery-detail__next icon icon--next"
              v-if="this.currentImg !== this.galleryCount"
              @click="slideNextHandler"
              ref="galleryNextButton"
        ></span>

        <!-- Gallery description/ alt -->
        <h2 class="gallery-detail__description">{{ galleryData.targetAlt }}</h2>

        <!-- Close button -->
        <span class="gallery-detail__close icon icon--close"
              @click="closeGalleryHandler"
        ></span>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "Gallery",

  data() {
    return {
      currentImg: '',
      galleryCount: '',
    }
  },

  props: {
    isGalleryOpen: Boolean,
    galleryData: Object,
  },

  methods: {
    closeGalleryHandler() {
      this.$emit('toggleGallery', false);
    },

    closeGalleryListenerHandler(e) {
      if (e.target?.id === 'gallery-detail' || e.keyCode === 27) {
        this.$emit('toggleGallery', false);
      }
    },

    slideGalleryByArrows(e) {
      if (e.which === 39) { // Arrow right
        this.slideNextHandler();
      } else if (e.which === 37) { // Arrow left
        this.slidePreviousHandler();
      }
    },

    slideNextHandler() {
      let nextImg = this.currentImg + 1;

      this.galleryData.images.some((image) => {
        if (nextImg === image.id) {
          this.$emit('updateGalleryData',
              image.src,
              image.alt,
              image.title,
              image.id
          );

          this.currentImg = image.id;
        }
      });
    },

    slidePreviousHandler() {
      let nextImg = this.currentImg - 1;

      this.galleryData.images.some((image) => {
        if (nextImg === image.id) {
          this.$emit('updateGalleryData',
              image.src,
              image.alt,
              image.title,
              image.id
          );

          this.currentImg = image.id;
        }
      });
    },
  },

  watch: {
    isGalleryOpen() {
      // Hide next/ previous button on first/ last image
      if (this.isGalleryOpen) {
        this.galleryCount = this.galleryData.images.length;

        this.$nextTick(() => {
          this.currentImg = this.galleryData.targetId;
        });
      }
    }
  },

  mounted() {
    /* Event listeners for close gallery detail */
    document.addEventListener('click', this.closeGalleryListenerHandler);
    document.addEventListener('keydown', this.closeGalleryListenerHandler);
    document.addEventListener('keyup', this.slideGalleryByArrows);
  },

  beforeDestroy() {
    /* Remove event listeners while gallery detail is closed */
    document.removeEventListener('click', this.closeGalleryListenerHandler);
    document.removeEventListener('keydown', this.closeGalleryListenerHandler);
    document.removeEventListener('keyup', this.slideGalleryByArrows);
  },
}
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts';

.gallery-detail {
  position: absolute;
  left: 50%;
  padding: 0 50px 0;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  z-index: 1001;
  overflow: hidden;
  text-align: center;
  opacity: 1;

  @include until($breakpoint-small) {
    padding: 0 10px 0;
  }

  &__content {
    position: relative;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    padding: rem(10);
    max-width: rem(1200);
    color: $color-text-light;
    background-color: $color-background-secondary;
  }

  &__image {
    margin: 0 auto;
    max-height: 90vh;
  }

  &__description {
    margin-top: rem(10);
    font-size: rem(16);

    @include until($breakpoint-small) {
      font-size: rem(14);
    }
  }

  // Icons
  &__next,
  &__previous {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__next {
    right: 0;
  }

  &__previous {
    left: 0;
  }

  &__close {
    position: absolute;
    top: rem(20);
    right: rem(20);
  }
}

// Animation for open & close gallery detail
.toggle-gallery-enter-active,
.toggle-gallery-leave-active {
  transition: opacity .15s;
}

.toggle-gallery-enter,
.toggle-gallery-leave-to {
  opacity: 0;
}
</style>
