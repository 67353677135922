/**
 * @description Flattens field
 * @param {Array} arr
 * @returns {Array}
 */
const flattenArrays = (arr) => {
  let newArray = [];
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      newArray = newArray.concat(flattenArrays(arr[i]));
    } else {
      newArray.push(arr[i]);
    }
  }
  return newArray;
};

export default flattenArrays;
