<template>
  <div class="grid__program-list program-list">
    <div
      v-for="({ id }, rowIndex) in channels"
      :key="id"
      :id="`program-row-${id}`"
      class="program-list__program-row"
      ref="programRow"
    >
      <div class="program-list__inner">
        <div
          class="program-list__program"
          :class="{ 'program-list__program--unavailable': id === 'unavailable' }"
          v-for="({ id, attributes }, i) in selectedPrograms[id]"
          :key="id + i"
          :style="{
            width: attributes.width,
            left: attributes.left,
          }"
          :title="attributes.name"
          @click="(event) => onClick(event, id, attributes, rowIndex)"
          @mousedown="onMouseDown"
        >
          <div class="program-list__program-title">
            {{ attributes.name }}
          </div>
          <div v-if="id !== 'unavailable'" class="program-list__program-meta">
            <span class="program-list__program-time">{{ formatTime(attributes.minutes) }}</span>
            -
            <span>{{ formatTime(attributes.minutes + attributes.lengthMinutes) }}</span>
            <span class="program-list__program-duration">{{
              getDuration(attributes.lengthMinutes)
            }}</span>
          </div>
          <div v-if="parseInt(attributes.width, 10) < 140" class="program-list__tooltip">
            <div class="program-list__tooltip-title">
              {{ attributes.name }}
            </div>
            <div v-if="id !== 'unavailable'" class="program-list__tooltip-meta">
              <span class="program-list__tooltip-time">{{ formatTime(attributes.minutes) }}</span>
              -
              <span>{{ formatTime(attributes.minutes + attributes.lengthMinutes) }}</span>
              <span class="program-list__tooltip-duration">{{
                getDuration(attributes.lengthMinutes)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {MINUTE_SIZE_IN_PIXELS, USER_DATE_CHANGE_TIMEOUT} from '@/config';
import {analyticsData, getDate} from '@/utils';
import {TOGGLE_PROGRAM_DETAIL} from '@/store/actions/program-detail';

const CLASS_VISIBILITY = 'invisible';
const IO_VISIBILITY_TIMEOUT = 200;

let userPositionTimeout;

export default {
  name: 'GridProgramList',

  data() {
    return {
      selectedPrograms: [],
      observer: null,
      pixelSize: MINUTE_SIZE_IN_PIXELS,
      sortedChannels: {},
      sortedChannelsCategory: {},
    };
  },

  computed: {
    ...mapGetters(['initialTimestamp', 'channels', 'programs', 'userPosition', 'channelsCategory']),
  },

  watch: {
    'userPosition.dates'() {
      clearTimeout(userPositionTimeout);

      userPositionTimeout = setTimeout(() => {
        this.selectPrograms();
      }, USER_DATE_CHANGE_TIMEOUT);
    },

    programs() {
      this.selectPrograms();
    },

    channels() {
      this.sortedChannels = this.groupBy(this.channels, 'id');
    },

    channelsCategory(data) {
      this.sortedChannelsCategory = this.groupBy(data, 'id');
    },
  },

  mounted() {
    if ('IntersectionObserver' in window) {
      let timeouts = {};

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            timeouts[entry.target.id] = setTimeout(() => {
              entry.target.classList.remove(CLASS_VISIBILITY);
            }, IO_VISIBILITY_TIMEOUT);
          } else {
            clearTimeout(timeouts[entry.target.id]);
            entry.target.classList.add(CLASS_VISIBILITY);
          }
        });
      });
    }
  },

  updated() {
    if (this.$refs.programRow && 'IntersectionObserver' in window) {
      this.$refs.programRow.forEach((row) => this.observer.observe(row));
    }
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {
    onMouseDown(event) {
      this.startClick = event.clientX;
    },

    onClick(event, id, attributes, rowIndex) {
      if (this.startClick === event.clientX && id !== 'unavailable') {
        this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, parseInt(id, 10));

        /* GTM | Web & Apps */
        this.gtmSelectProgramEvent(attributes, rowIndex, id);
      }
    },

    formatDate(timestamp) {
      return getDate.toMinutes(timestamp);
    },

    formatTime(timestamp) {
      const { hours, minutes } = getDate.parsedDate(timestamp * 1000 * 60);
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    },

    getDuration(timestamp) {
      const { hours, minutes } = getDate.toTime(timestamp);
      return `${hours ? `${hours} hod. ` : ``}${minutes}  min.`;
    },

    selectPrograms() {
      const dates = this.userPosition.dates || [];

      this.selectedPrograms = dates.reduce((acc, date) => {
        const programs = this.programs[date];
        if (programs && Object.keys(programs).length > 0) {
          Object.keys(programs).forEach((program) => {
            if (!acc[program]) acc[program] = [];
            acc[program] = [...acc[program], ...this.programs[date][program]];
          });
        }
        return acc;
      }, {});
    },

    gtmSelectProgramEvent(attributes, rowIndex, id) {
      // const channelCategory = this.sortedChannels[attributes.station][0].attributes.category;

      analyticsData.send({
        'event': 'tvProgram_timeline_selectShow',
        'item': {
          'type': 'tvProgram',
          'title': attributes.name,
          'category': this.sortedChannels[attributes.station][0].attributes.name,
          // 'list': this.sortedChannelsCategory[channelCategory][0].attributes.name,
          'position': rowIndex + 1,
          'timeDate': attributes.startDateTime,
          'url': window.location.href + '#detail=' + id
        }
      });
    },

    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        let key = obj[property];

        if (!acc[key]) acc[key] = [];

        acc[key].push(obj);
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.program-list {
  &__program-row {
    position: relative;
    display: flex;
    height: rem(73);
    border-top: rem(2) solid $color-border-primary;

    &.invisible {
      .program-list__inner {
        display: none;
      }
    }
  }

  &__program,
  &__tooltip {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-background-light;
    border-left: rem(2) solid $color-border-primary;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      z-index: 4;

      &::after {
        content: '';
        position: absolute;
        top: rem(-2);
        left: rem(-2);
        right: rem(-2);
        bottom: rem(-2);
        border: rem(2) solid $color-border-hover;
        z-index: 1;
      }

      .program-list__program-title,
      .program-list__tooltip-title {
        text-decoration: underline;
      }

      .program-list__tooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__program--unavailable {
    background-color: $color-background-primary;
    pointer-events: none;
    background-size: 40px 40px;
    background-image: linear-gradient(
      135deg,
      rgba($color-background-secondary, 0.015) 25%,
      transparent 25%,
      transparent 50%,
      rgba($color-background-secondary, 0.015) 50%,
      rgba($color-background-secondary, 0.015) 75%,
      transparent 75%,
      transparent
    );
  }

  &__tooltip {
    display: none;
    border-left: 0;
    z-index: 2;
  }

  &__program-title,
  &__tooltip-title {
    font-weight: bold;
    padding: 0 rem(11);
    overflow: hidden;
  }

  &__program-meta,
  &__tooltip-meta {
    font-size: rem(11);
    padding: 0 rem(11);
    margin-top: rem(6);
    overflow: hidden;
  }

  &__program-duration,
  &__tooltip-duration {
    color: lighten($color-text-dark, 50%);
    margin-left: rem(10);
  }
}
</style>
