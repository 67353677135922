<template>
  <div class="horizontal-strip horizontal-strip--slider horizontal-strip--gallery">
    <div ref="slidePreviousButton"
         class="horizontal-strip__slider-previous slider-previous icon icon--previous"
         @click="slidePreviousHandler"
    ></div>
    <div ref="galleryWrapper" class="horizontal-strip__content gallery" @scroll="onScroll">
      <ul ref="galleryList" class="gallery__list">
        <li class="gallery__item gallery__item--iframe"
            v-for="(trailer, index) in programDataTrailers"
            :key="index"
            ondragstart="return false;"
            ref="galleryItem"
        >
          <iframe :src="getYoutubeVideoId(trailer)"
                  class="gallery__iframe"
                  ondragstart="return false;"
          >
          </iframe>
        </li>
        <!-- Images -->
        <li class="gallery__item gallery__item--image"
            v-for="image in galleryImages"
            :key="image.src"
            ref="galleryItem"
        >
          <img :src="image.src"
               :alt="image.alt"
               :title="image.title"
               :data-id="image.id"
               @click="openGallery($event)"
               class="gallery__image"
               width="700"
               ondragstart="return false;"
               loading="lazy"
          >
        </li>
      </ul>
    </div>
    <div ref="slideNextButton"
         class="horizontal-strip__slider-next slider-next icon icon--next"
         @click="slideNextHandler"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ProgramDetailGallery',

  props: {
    galleryImages: Array,
    programDataTrailers: Array,
  },

  data() {
    return {
      translate: 0,
    }
  },

  mounted() {
    this.toggleStepButtons();
  },

  methods: {
    openGallery($event) {
      this.originalTranslate = this.translate;
      if (this.originalTranslate !== this.translate) return false;

      this.$emit('toggleGallery', true);

      this.$emit('updateGalleryData',
          $event.target.src,
          $event.target.alt,
          $event.target.title,
          $event.target.getAttribute('data-id')
      );
    },

    getYoutubeVideoId(video) {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      let youtubeVideoId = video.match(regExp);

      if (youtubeVideoId && youtubeVideoId[2].length === 11) {
        return `https://www.youtube.com/embed/${youtubeVideoId[2]}`;
      }
    },

    doTranslate(position) {
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, position));

      this.getGalleryWrapper().scroll({
        left: this.translate,
        behavior: 'smooth',
      });

      this.toggleStepButtons();
    },

    getGalleryWrapper() {
      return this.$refs.galleryWrapper;
    },

    getInner() {
      return this.$refs.galleryList;
    },

    getMaxTranslate() {
      return this.getInner().clientWidth - this.getGalleryWrapper().clientWidth;
    },

    onScroll() {
      const galleryLeft = this.getGalleryWrapper().scrollLeft;

      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, galleryLeft));
      this.toggleStepButtons();
    },

    getSliderItemWidth() {
      const item = this.$refs.galleryItem[0];
      const itemMarginRight = parseInt(window.getComputedStyle(item).marginRight, 10);

      return item.clientWidth + itemMarginRight;
    },

    slideNextHandler() {
      this.doTranslate(this.translate + this.getSliderItemWidth(), true);
      this.toggleStepButtons();
    },

    slidePreviousHandler() {
      this.doTranslate(this.translate - this.getSliderItemWidth(), true);
      this.toggleStepButtons();
    },

    toggleStepButtons() {
      /* previous button */
      if (this.translate === 0 || this.getMaxTranslate() <= 0) {
        this.$refs.slidePreviousButton.style.display = 'none';
      } else {
        this.$refs.slidePreviousButton.style.display = '';
      }

      /* next button */
      if (this.translate === this.getMaxTranslate() || this.getMaxTranslate() <= 0) {
        this.$refs.slideNextButton.style.display = 'none';
      } else {
        this.$refs.slideNextButton.style.display = '';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/abstracts/abstracts';

  .horizontal-strip {
    &--gallery {
      display: flex;
    }

    &__content {
      margin-left: 0;
    }
  }

  .gallery {
    position: relative;
    display: flex;
    transform: translate3d(0, 0, 0);
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox hide scroll bar */

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover,
    &:focus {
      .gallery__slide-button {
        display: block;
      }
    }

    .slider-next,
    .slider-previous {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      z-index: 10;
    }

    .slider-next {
      right: 0;
    }

    .slider-previous {
      left: 0;
    }

    &__image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &__list {
      display: inline-flex;
    }

    &__item {
      width: rem(700);
      height: rem(362);
      margin-right: rem(20);

      &--image {
        background-color: $color-background-primary; // placeholder
      }

      &--iframe {
        display: flex;
      }

      &:last-child {
        margin-right: rem(50);
      }

      @include until($breakpoint-small) {
        width: rem(315);
        height: rem(163);
      }
    }

    &__iframe {
      width: 100%;
      border: 0;
    }
  }
</style>
