<template>
  <div class="guide__channels-filter channels-filter"
       ref="channelsFilter"
       @scroll="onFilterScroll"
       :class="{
      'channels-filter--fixed': headerPosition === 'fixed',
      'channels-filter--sticked': headerPosition === 'sticky',
    }"
  >
    <div class="channels-filter__inner" ref="inner" @mousedown="onMouseStart">
      <div
        v-for="{ id, attributes } in filters"
        :key="id"
        @click="() => clickHandler(id, attributes.name)"
        class="channels-filter__item"
        :class="{ 'channels-filter__item--selected': id === channelsFilter }"
      >
        {{ attributes.name }}
        <svg
          v-if="id === '1001'"
          ref="favoriteChannelsIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="13"
          viewBox="0 0 24 24"
          :style="[favoriteChannels.length === 0 ? { fill: 'transparent' } : '']"
        >
          <path
            d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
          />
        </svg>
        <span v-if="id === '1001'" class="channels-filter__favorite-counter">{{
          favoriteChannels.length
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_CHANNELS_FILTER, SET_RENDERING } from '@/store/actions/guide';
import { analyticsData, dragger } from '@/utils';
import { BREAKPOINT_MOBILE, FITLER_FAVOURITE } from '@/config';

export default {
  name: 'GuideChannelsFilter',

  data() {
    return {
      filters: [],
      translate: 0,
    };
  },

  props: {
    headerPosition: String,
  },

  computed: mapGetters(['channelsFilter', 'favoriteChannels', 'channelsCategory']),

  async mounted() {
    this.$nextTick(this.setInnerWidth);
    window.addEventListener('resize', this.onWindowResize);
  },

  watch: {
    channelsCategory(data) {
      this.filters = [FITLER_FAVOURITE, ...data];
    },

    async channels() {
      this.$store.dispatch(SET_RENDERING, true);
      await this.getPrograms();
      this.$store.dispatch(SET_RENDERING, false);
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    clickHandler(id, name) {
      if (this.originalTranslate && this.originalTranslate !== this.translate) return false;
      this.$store.dispatch(SET_CHANNELS_FILTER, id);

      /* GTM | Web & Apps */
      this.gtmFilterEvent(name);
    },

    getChannelsFilter() {
      return this.$refs.channelsFilter;
    },

    getInner() {
      return this.$refs.inner;
    },

    getInnerWidth() {
      return [...this.getInner().children].reduce(
        (acc, elem) =>
          (acc += elem.offsetWidth + parseInt(window.getComputedStyle(elem).marginRight, 10)),
        0
      );
    },

    getMaxTranslate() {
      return this.getInner().clientWidth - this.getChannelsFilter().clientWidth;
    },

    doTranslate(position) {
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, position));
      this.getChannelsFilter().scrollLeft = this.translate;
    },

    dragCallback(distance) {
      this.doTranslate(this.translate + distance);
    },

    onMouseStart(event) {
      this.originalTranslate = this.translate;
      dragger.mouseDrag(event, this.dragCallback);
    },

    onFilterScroll() {
      const channelsFiltersLeft = this.getChannelsFilter().scrollLeft;
      this.translate = Math.min(this.getMaxTranslate(), Math.max(0, channelsFiltersLeft));
    },

    setInnerWidth() {
      this.getInner().style.width =
        window.innerWidth <= BREAKPOINT_MOBILE ? `${this.getInnerWidth()}px` : 'initial';
    },

    onWindowResize() {
      this.translate = 0;
      this.setInnerWidth();
    },

    gtmFilterEvent(name) {
      analyticsData.send({ event: 'tvProgram_timeline_filter', item: { list: name } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.channels-filter {
  width: 100%;
  overflow: auto hidden;
  scrollbar-width: none;
  background-color: $color-background-primary;
  padding-top: rem(16);
  padding-bottom: rem(16);

  &::-webkit-scrollbar {
    display: none;
  }

  @include until($breakpoint-small) {
    &--fixed {
      position: fixed;
      z-index: 10;
      width: 100%;
      top: 0;
    }

    &--sticked {
      position: absolute;
      bottom: rem(112);
      left: 0;
      z-index: 10;
      width: 100%;
    }
  }
  @include from($breakpoint-small) {
    &--fixed {
      position: fixed;
      z-index: 10;
      width: 100%;
      top: -120px;
    }
  }

  @include from($breakpoint-small) {
    position: absolute;
    top: rem(-100);
    right: 0;
    left: 0;
    padding: 0;
    width: auto;
    background-color: transparent;
  }

  @include from($breakpoint-header) {
    position: absolute;
    top: rem(-138);
    right: rem(365);
    left: rem(225);
    padding: 0;
    width: auto;
    background-color: transparent;
  }

  @include from($breakpoint-header-large) {
    top: rem(-103);
  }

  @include from($breakpoint-header-big) {
    top: rem(-58);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(15);
    margin-right: rem(15);

    @include from($breakpoint-small) {
      margin-left: rem(30);
      margin-right: rem(30);
    }

    @include from($breakpoint-header) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__item {
    display: flex;
    font-size: rem(13);
    font-weight: bold;
    line-height: 1;
    margin-top: 0;
    margin-right: rem(10);
    margin-bottom: rem(10);
    padding: rem(8) rem(15);
    border: rem(2) solid $color-border-primary;
    border-radius: rem(6);
    white-space: nowrap;
    cursor: pointer;
    transition: border-color 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:last-child {
      margin-right: rem(0);
    }

    svg {
      margin-left: rem(5);
      fill: $color-other-heart;
      stroke: $color-other-heart;
      stroke-width: 2px;
      position: relative;
    }

    &:hover:not(.channels-filter__item--selected) {
      border-color: $color-border-hover;
    }
  }

  &__favorite-counter {
    display: inline-block;
    margin-left: rem(4);
    font-size: rem(13);
  }

  &__item--selected {
    background-color: $color-highlight-primary;
    color: $color-white;
    border-color: $color-highlight-primary;

    svg {
      fill: $color-white;
      stroke: $color-white;
    }
  }
}
</style>
