import { PROGRAMS, PROGRAMS_ARTICLES, PROGRAMS_CURRENT, PROGRAMS_SEARCH } from '@/api/constants';
import { makeRequest } from '@/utils';
import { PROGRAMS_TIPS } from './constants/index';

export default {
  /**
   * @description Returns a list of all current programs.
   */
  getProgramsCurrent: async () => {
    try {
      const response = await makeRequest.get(PROGRAMS_CURRENT);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {};
    }
  },

  /**
   * @description Returns a list of all programs by search string.
   * @param {String} searchString
   */
  getProgramsSearch: async (searchString) => {
    try {
      const response = await makeRequest.get(`${PROGRAMS_SEARCH}/${searchString}`);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {
        status: 'error',
      };
    }
  },

  /**
   * @description Returns a program.
   * @param {Number} id
   */
  getProgram: async (id) => {
    try {
      const response = await makeRequest.get(`${PROGRAMS}/${id}`);
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {
        status: 'error',
      };
    }
  },

  /**
   * @description Returns a list of all articles by program.
   * @param {Array} programs
   */
  getProgramArticles: async (programs) => {
    try {
      const response = await makeRequest.post(PROGRAMS_ARTICLES, {
        program_names: programs,
      });
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return {};
    }
  },

  /**
   * @description Returns a list of program tips.
   * @param {string} type ['today', 'weekend', 'sunday-morning']
   */
  getProgramTips: async ({ date_start, time_from, time_to, stations }) => {
    try {
      const response = await makeRequest.get(
        `${PROGRAMS_TIPS}?date_start=${date_start}&time_from=${time_from}&time_to=${time_to}&stations=${stations}`
      );
      return response.data.data;
    } catch (error) {
      console.warn(error);
      return [];
    }
  },
};
