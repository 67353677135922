import Vue from 'vue';
import Vuex from 'vuex';
import guide from '@/store/modules/guide';
import programDetail from '@/store/modules/program-detail';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    guide,
    programDetail,
  },
});
