import { TOGGLE_PROGRAM_DETAIL } from '@/store/actions/program-detail';

const state = {
  programDetailId: null,
};

const getters = {
  programDetailId: (state) => state.programDetailId,
};

const actions = {
  [TOGGLE_PROGRAM_DETAIL]: ({ commit }, payload) => {
    if (typeof payload === 'number') {
      commit(TOGGLE_PROGRAM_DETAIL, payload);
    } else {
      commit(TOGGLE_PROGRAM_DETAIL, null);
    }
  },
};

const mutations = {
  [TOGGLE_PROGRAM_DETAIL]: (state, payload) => {
    state.programDetailId = payload;
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
