<template>
  <div class="whisperer" v-if="isOpen">
    <div v-if="loading" class="whisperer__loading">
      <span class="status__icon icon icon--spinner-dark icon--40x40"></span>
    </div>
    <div v-else class="whisperer__content" ref="content">
      <div v-if="this.channels.length !== 0" class="whisperer__content-section">
        <div class="whisperer__content-title">Stanice</div>
        <ul>
          <li
            v-for="(channel, i) in channels.slice(0, 5)"
            :key="channel.id"
            @click="onChannelsClick(channel)"
            class="whisperer__item"
            :class="{ 'is-active': i === counterChannels }"
          >
            <span class="whisperer__item-image">
              <img :src="channel.attributes.logoUrl" :alt="channel.attributes.name" />
            </span>
            <span class="whisperer__item-text" v-html="formatText(channel.attributes.name)"> </span>
          </li>
        </ul>
      </div>
      <div v-if="this.programs.length !== 0" class="whisperer__content-section">
        <div class="whisperer__content-title">Programy</div>
        <ul>
          <li
            v-for="({ id, attributes }, i) in programs.slice(0, 5)"
            :key="id"
            @click="onProgramClick(id)"
            :class="{ 'is-active': i === counterPrograms }"
          >
            <span class="whisperer__item-text" v-html="formatText(attributes.name)"></span>
            <span class="whisperer__item-text-meta">
              {{ formatDate(attributes.startDateTime) }}</span
            >
          </li>
        </ul>
      </div>
      <a :href="`/hledani?search=${query}`" class="whisperer__direct-link"
        >Zobrazit kompletní výsledky</a
      >
    </div>
  </div>
</template>

<script>
import { API } from '@/api';
import { SET_CHANNELS, SET_CHANNELS_FILTER_EMPTY } from '@/store/actions/guide';
import { TOGGLE_PROGRAM_DETAIL } from '@/store/actions/program-detail';
import { PARAM_STATION } from '@/config';
import { getDate, hightlightText } from '@/utils';

export default {
  name: 'SearchWhisperer',

  data() {
    return {
      channels: [],
      programs: [],
      typeDelay: null,
      loading: false,
      counterChannels: -1,
      counterPrograms: -1,
    };
  },

  props: {
    query: String,
  },

  computed: {
    isOpen() {
      return this.loading || this.channels.length !== 0 || this.programs.length !== 0;
    },
  },

  watch: {
    query() {
      const { query } = this;
      clearTimeout(this.typeDelay);
      this.$emit('onChannelsClick', false);

      if (query.length > 2) {
        this.loading = true;

        this.typeDelay = setTimeout(async () => {
          if (query.length < 3) return;

          const [channels, programs] = await Promise.all([
            API.search.getChannels(query),
            API.search.getPrograms(query),
          ]).finally(() => {
            this.loading = false;
          });

          this.channels = channels;
          this.programs = programs;
        }, 300);
      } else {
        this.hide();
      }
    },

    isOpen() {
      if (this.isOpen) {
        document.addEventListener('click', this.hide);
        document.addEventListener('keydown', this.onKeyboardClick);
      } else {
        document.removeEventListener('click', this.hide);
        document.addEventListener('keydown', this.onKeyboardClick);
      }
    },
  },

  methods: {
    hide() {
      this.channels = [];
      this.programs = [];
      this.loading = false;
      this.counterPrograms = -1;
      this.counterChannels = -1;
    },

    onChannelsClick(channel) {
      // If the user isn't on the homepage ... logic processed in Guide.vue
      if (window.location.pathname !== '/') {
        window.location.href = `/?${PARAM_STATION}=${channel.id}`;
        return;
      }
      this.$store.dispatch(SET_CHANNELS_FILTER_EMPTY);
      this.$store.dispatch(SET_CHANNELS, [channel]);
      this.$emit('onChannelsClick', true);
    },

    onProgramClick(id) {
      this.$store.dispatch(TOGGLE_PROGRAM_DETAIL, parseInt(id, 10));
    },

    formatText(text) {
      return hightlightText(text, this.query);
    },

    formatDate(stringDate) {
      const date = new Date(stringDate);
      const { day, month, hours, minutes } = getDate.parsedDate(stringDate);
      const longDay = `0${day}`.slice(-2);
      const longMonth = `0${month}`.slice(-2);
      const longHours = `0${hours}`.slice(-2);
      const longMinutes = `0${minutes}`.slice(-2);

      return getDate.isToday(date)
        ? `dnes ${longHours}:${longMinutes}`
        : `${longDay}.${longMonth} ${longHours}:${longMinutes}`;
    },

    onKeyboardClick(e) {
      let channels = this.channels.length < 5 ? this.channels.length : 5;
      let programs = this.programs.length < 5 ? this.programs.length : 5;

      switch (e.code) {
        case 'ArrowDown':
          this.arrowDown(channels, programs);
          break;

        case 'ArrowUp':
          this.arrowUp(channels, programs);
          break;

        case 'Enter':
          this.enter(channels, programs, e);
          this.hide();
          break;

        case 'Escape':
          this.hide();
          break;
      }
    },

    arrowDown(channels, programs) {
      if (this.counterChannels < channels - 1) {
        this.counterChannels = this.counterChannels + 1;
      }
      else if (this.counterPrograms < programs - 1) {
        this.counterChannels = this.counterChannels + 1;
        this.counterPrograms = this.counterPrograms + 1;
      }
    },

    arrowUp(channels, programs) {
      if (this.counterChannels > 0 && this.counterChannels <= channels) {
        this.counterChannels = this.counterChannels - 1;
      }
      else if (this.counterPrograms >= 0 && this.counterPrograms <= programs) {
        this.counterPrograms = this.counterPrograms - 1;

        if (this.counterPrograms < 0) {
          this.counterChannels = channels - 1;
        }
      }
    },

    enter(channels, programs, e) {
      if ((this.counterChannels && this.counterPrograms) >= 0) {
        e.preventDefault();
      }

      if (this.counterChannels > -1 && this.counterChannels <= channels && this.counterPrograms < 0) {
        this.onChannelsClick(this.channels[this.counterChannels]);
      }
      else if (this.counterPrograms > -1 && this.counterPrograms <= programs && (this.counterChannels < 0 || this.counterChannels > channels)) {
        this.onProgramClick(this.programs[this.counterPrograms].id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/abstracts/abstracts.scss';

.whisperer {
  position: absolute;
  padding: rem(10) rem(15);
  background-color: #fff;
  border: rem(2) solid $color-border-primary;
  border-radius: rem(6);
  box-shadow: 0 rem(5) rem(10) rem(2) rgba(0, 0, 0, 0.2);
  z-index: 100;
  font-size: rem(13);

  &__loading {
    display: flex;
    justify-content: center;
    opacity: 0.3;
  }

  &__content {
    > div:first-child {
      margin-bottom: rem(5);
    }
  }

  &__content-title {
    display: flex;
    align-items: center;
    padding: rem(3) 0;
    color: $color-text-grey;

    &:after {
      content: '';
      width: 100%;
      height: rem(1);
      margin-left: rem(8);
      background-color: lighten($color-text-grey, 20%);
    }
  }

  &__content-section {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: rem(8) rem(10) rem(7);

        &:hover,
        &.is-active {
          background-color: lighten($color-text-grey, 25%);
          cursor: pointer;
        }
      }
    }
  }

  &__item-image {
    width: rem(22);
    margin-right: rem(10);
  }

  &__item-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item-text-meta {
    width: rem(60);
    font-size: rem(11);
    color: $color-text-grey;
    text-align: right;
  }

  &__direct-link {
    display: block;
    padding: rem(8) 0;
    color: $color-highlight-secondary-hover;
    text-decoration: underline;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
