/**
 * @description Splits array into multiple chunks.
 * @param {Array} arr
 * @param {Number} chunkSize
 */
export default (arr, chunkSize) => {
  const r = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    r.push(arr.slice(i, i + chunkSize));
  }
  return r;
};
