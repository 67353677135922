/**
 * @param {string} str
 * @returns {string}
 */
const toLowerWithoutAccent = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

/**
 * @description Highlight text strings.
 * @param {string} text
 * @param {string} searchText
 * @returns {string}
 */
export default (text, searchText) => {
  const searchWords = searchText.split(' ');
  let result = text;

  const uniqWords = searchWords
    .sort((a, b) => b.length - a.length)
    .reduce((acc, curr) => {
      if (!acc.some((word) => toLowerWithoutAccent(word).includes(toLowerWithoutAccent(curr)))) {
        acc.push(curr);
      }
      return acc;
    }, []);

  uniqWords
    .reduce((indexes, word) => {
      const start = toLowerWithoutAccent(text).search(toLowerWithoutAccent(word));
      if (start >= 0) indexes.push([start, start + word.length]);
      return indexes;
    }, [])
    .sort((fIndex, sIndex) => sIndex[0] - fIndex[0])
    .forEach((index) => {
      result = `${result.slice(0, index[1])}</b>${result.slice(index[1])}`;
      result = `${result.slice(0, index[0])}<b>${result.slice(index[0])}`;
    });

  return result;
};
