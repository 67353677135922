export default {
  /**
   * @description It starts tracking movement until a mouseup event occurs.
   * @param {MouseEvent} event
   * @param {Function} callback Will be called with distance number.
   */
  mouseDrag: (event, callback) => {
    let startX = event.clientX;

    const mouseMove = (event) => {
      const currentX = startX - event.clientX;
      startX = event.clientX;
      callback(currentX);
    };

    const mouseEnd = () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', mouseEnd);
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', mouseEnd);
  },

  /**
   * @description It starts tracking movement until a touchend event occurs.
   * @param {TouchEvent} event
   * @param {Function} callback Will be called with distance number.
   */
  touchDrag: (event, callback) => {
    let startX = event.touches[0].pageX;

    const touchMove = (event) => {
      const currentX = startX - event.touches[0].pageX;
      startX = event.touches[0].pageX;
      callback(currentX);
    };

    const touchEnd = () => {
      window.removeEventListener('touchmove', touchMove);
      window.removeEventListener('touchend', touchEnd);
    };

    window.addEventListener('touchmove', touchMove);
    window.addEventListener('touchend', touchEnd);
  },
};
