// Guide
export const STORAGE_KEY = 'tvp';
export const REQUEST_CHUNK_LIMIT = 200;
export const INITIAL_PROGRAMS_NUMBER = 10;
export const NEXT_LOAD_PROGRAMS_NUMBER = 20;

export const FITLER_FAVOURITE = { id: '1001', attributes: { name: 'Oblíbené' } };
export const FITLER_DEFAULT = '2';

// Grid
export const MINUTE_SIZE_IN_PIXELS = 5;
export const DAYS_NUMBER_VISIBLE = { desktop: 11, mobile: 8 }; // dependence on style date-strip__inner!!!
export const DAYS_NUMBER = 21;
export const DAYS_NUMBER_BACK = 7;
export const INITIAL_POINTER_OFFSET = 270;
export const NAME_DAYS = ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'];
export const USER_DATE_CHANGE_TIMEOUT = 500;

// Program detail
export const INITIAL_NUMBER_PROGRAM_ARTICLES = 6;
export const LOAD_MORE_NUMBER_PROGRAM_ARTICLES = 6;

// Progress bar reload
export const PROGRESS_BARS_REFRESH = 60000;
export const TOGGLE_PROGRAMS = 60000;
export const API_PROGRAMS_REFRESH = 60000 * 25;

// CSS
export const BREAKPOINT_MOBILE = 768;

// Search
export const INITIAL_NUMBER_RESULTS = 5;
export const LOAD_MORE_NUMBER_RESULTS = 5;

// URL params
export const PARAM_STATION = 'stanice';
